
"use client";
import React from "react";

const Category = (props) => {
  console.log(props)
  
  return (
    <div className="hero-catagory mar-top-30 section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="hero-catagory-wrapper v1">
              {props?.data?.map((item,index) => (
                (index<=5) && (
                  <a href="grid-fullwidth.html" className="hero-category-content v1">
                <i className="icofont-hotel"></i>
                <p className="name">{item.name}</p>
                <p className="d-name">{item.category.length} Locations</p>
              </a>
                )
              
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
