import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { actions } from '../../../actions/actions';
import Heading from '../../../heading';
import HorizontalMenu from '../../../horizontalMenu';

const AdminList = props => {
    const stateData = useSelector((state) => state);
    const navigate = useNavigate();
    const [profileData, setprofileData] = useState([]);
    // alert(JSON.stringify(profileData));
    const [pagination, setPagination] = useState([]);
    const [currentPage, setCurrentPage] = useState([]);
    const dispatch = useDispatch();
    const compDetails = (details) => {
        localStorage.setItem("compDetails", JSON.stringify(details));
        setTimeout(() => {
            navigate('/admin/company/edit');
        }, 500);
    }
    var companyprofile = [];
    useEffect(() => {
        setprofileData(stateData.compProfile ? stateData.compProfile.data : []);
        setPagination(stateData.compProfile ? stateData.compProfile.links : []);
        setCurrentPage(stateData.compProfile ? stateData.compProfile.current_page : []);
        // console.log(stateData.compProfile.data)
        // alert(JSON.stringify(stateData.compProfile.links))
    }, [JSON.stringify(stateData.compProfile)])

    const requestList = (selectedPage) => {
        dispatch(actions.getCompProfile({ name: "all", id: 0, page: selectedPage }))
    }

    return (
        <>
        <HorizontalMenu list="#" add="#" />
            <section class="bg-light py-5 height100vh">
                <div class="container">
                    <Heading heading="Company List" />
                    <div class="card">
                <div className="row">
                    <div className="col-sm-12 col-xl-12">
                        <div className="card-body p-0 table-responsive">
                            <table className='table table-hover'>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>Category</th>
                                        <th>About</th>
                                        <th>Address</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {profileData?.map((list) => {
                                        return <tr>
                                            <td><input type="checkbox"></input></td>
                                            <td>{list.title}</td>
                                            <td>{list.category ? list.category.name : ""}</td>
                                            <td>{list.description?list.description.substring(0, 200):""}</td>
                                            <td>{list.address}</td>
                                            <td>{list.address}</td>
                                            <td><button onClick={()=>compDetails(list)} className='btn btn-sm btn-primary'>Edit</button></td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>


                        </div>
                    </div>
                </div>
            </div>
            <section className="my-5">

                <nav aria-label="Page navigation example">
                    <ul className="pagination">
                        {/* <li className="page-item mr-2">
                            <a className="page-link" href="#">
                                <i className="fa fa-angle-left" aria-hidden="true"></i>
                            </a>
                        </li> */}
                        {pagination.map((page, index) => {
                            var pageLabel = parseInt(page.label);
                            var active = page.active ? "active" : "";
                            return <li className={'page-item mr-2 ' + active}>
                                <span className="page-link" onClick={() => requestList(pageLabel)}>
                                    {index == 0 ? <i className='fa fa-angle-left'></i> :
                                        index == pagination.length - 1 ? <i className='fa fa-angle-right'></i> :
                                            page.label
                                    }</span></li>
                        })}

                        {/* <li className="page-item">
                            <a className="page-link" href="#">
                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                            </a>
                        </li> */}
                    </ul>
                </nav>
            </section>
            </div>
            </section>

            {/* {!profileData.length ?
                <div class="alert alert-info" role="alert">
                    <h4>No data found</h4>
                </div>
                : ""} */}


            
    </>
    );
};

export default AdminList;