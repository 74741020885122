import { Link } from "react-router-dom";

function Footer() {
  return (
    <div
      className="footer-wrapper no-pad-tb v1"
      style={{ backgroundImage: 'url("/images/bg/footer-bg-1.png")' }}
    >
      <div className="overlay op-1"></div>
      <div className="footer-top-area section-padding">
        <div className="container">
          <div className="row nav-folderized">
            <div className="col-lg-3 col-md-12">
              <div className="footer-logo">
                <a href="index.html">
                  {" "}
                  <img src="logo.jpg" alt="logo" />
                </a>
                <div className="company-desc">
                  <p>
                  connect2buyers is the best and largest searchable B2B
                    trading platform based in Pitampura. Here, you can access
                    the wide supplier network with a seamless trading
                    experience. We have grown thousands of small and large
                    businesses globally with the help of our 13+ years of
                    remarkable experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="footer-content nav">
                <h2 className="title">Helpful Links</h2>
                <ul className="list">
                  <li>
                    <Link className="link-hov style2" to="/assets/connect2buyers.pdf" target={"_blank"}>
                      Terms and Conditions
                    </Link>
                  </li>

                  <li>
                    <Link  className="link-hov style2" to="payments">Payment</Link>
                  </li>
                  <li>
                    <Link  className="link-hov style2" to="pricing">Pricing</Link>
                  </li>
                  <li>
                    <a
                     className="link-hov style2"
                      href="https://connect2buyers.com/export.pdf"
                      target="_blank"
                    >
                      Export Import
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="footer-content nav">
                <h2 className="title">Category</h2>
                <ul className="list">
                  <li>
                    <Link className="link-hov style2" to="/">Home</Link>
                  </li>
                  <li>
                    <Link className="link-hov style2" to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link className="link-hov style2" to="/register">Create Account</Link>
                  </li>
                  <li>
                    <Link className="link-hov style2" to="/login">Login</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="footer-content nav">
                <h2 className="title">Contact us</h2>
                <ul className="list footer-list">
                  <li>
                    <div className="contact-info">
                      <div className="icon">
                        <i className="ion-ios-location"></i>
                      </div>
                      <div className="text">13th North Ave, Florida, USA</div>
                    </div>
                  </li>
                  <li>
                    <div className="contact-info">
                      <div className="icon">
                        <i className="ion-email"></i>
                      </div>
                      <div className="text">
                        <a href="#">info@listagram.com</a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="contact-info">
                      <div className="icon">
                        <i className="ion-ios-telephone"></i>
                      </div>
                      <div className="text">+44 078 767 595</div>
                    </div>
                  </li>
                </ul>
                <ul className="social-buttons style2">
                  <li>
                    <a href="#">
                      <i className="ion-social-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ion-social-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ion-social-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ion-social-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ion-social-dribbble"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom-area">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 text-center">
              <p>&copy; 2019 listagram. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <footer className="pt-7 pt-md-10 bg-dark">
    //   <div className="container">
    //     <div className="row">
    //       <div className="col-sm-7 col-xs-12">
    //         {/* <Link class="menuzord-brand" to="/"><img src="/assets/img/logo.jpeg" style={{ width: '42%' }} /></Link> */}
    //         <p className="pt-1 pb-3 text-white">
    //           connect2buyers is the best and largest searchable B2B trading
    //           platform based in Pitampura. Here, you can access the wide
    //           supplier network with a seamless trading experience. We have grown
    //           thousands of small and large businesses globally with the help of
    //           our 13+ years of remarkable experience. You can connect with
    //           verified and filtered buyers to give your business a magical edge.
    //           We are dedicated to transforming the way businesses connect,
    //           collaborate, and succeed in the digital marketplace.
    //         </p>
    //         <ul className="list-unstyled text-white">
    //           <li>
    //             <i className="fa fa-phone mr-3" aria-hidden="true"></i>
    //             <a href="tel:Sales : +91 9717505051">
    //               Sales : +91 9717505051
    //             </a>
    //           </li>
    //           <li>
    //             <i className="fa fa-phone mr-3" aria-hidden="true"></i>
    //             <a href="tel:Sales : +91 9717505052">
    //               Support : +91 9717505052
    //             </a>
    //           </li>
    //           <li>
    //             <i className="fa fa-envelope mr-3" aria-hidden="true"></i>
    //             <a href="mailto:info@dgmt.in">
    //               info@connect2buyers.com
    //             </a>
    //           </li>
    //         </ul>
    //       </div>

    //       <div className="col-sm-3 col-xs-12">
    //         <div className="mb-4 mt-4 mt-md-0">
    //           <h2 className="h4 text-white">Links</h2>
    //         </div>
    //         <ul className="list-unstyled list-gray">
    //           <li>
    //             <Link to="/">
    //               Home
    //             </Link>
    //           </li>
    //           <li>
    //             <Link to="/about-us">
    //               About Us
    //             </Link>
    //           </li>
    //           <li>
    //             <Link to="/register">
    //               Create Account
    //             </Link>
    //           </li>
    //           <li>
    //             <Link to="/login">
    //               Login
    //             </Link>
    //           </li>
    //           <li>
    //             <a
    //               target="_blank"
    //
    //               href="https://dgmt.in/index.php/dgmt-review/"
    //             >
    //               Review
    //             </a>
    //           </li>
    //           {/* <li>
    //                             <a href="add-listings.html">Add Listing</a>
    //                         </li>
    //                         <li>
    //                             <a href="edit-listings.html">Edit Listing</a>
    //                         </li> */}
    //         </ul>
    //       </div>

    //       <div className="col-sm-2 col-xs-12">
    //         <div className="mb-4 mt-4 mt-md-0">
    //           <h2 className="h4 text-white">Company</h2>
    //         </div>
    //         <ul className="list-unstyled list-gray">
    //           {/* <li>
    //                             <a href="contact-us.html">Contact Us</a>
    //                         </li> */}
    //           <li>
    //             <Link
    //
    //               to="/assets/connect2buyers.pdf"
    //               target={"_blank"}
    //             >
    //               Terms and Conditions
    //             </Link>
    //           </li>
    //           {/* <li>
    //                             <a href="how-it-works.html">How It Works</a>
    //                         </li> */}
    //           <li>
    //             <Link to="payments">
    //               Payment
    //             </Link>
    //           </li>
    //           <li>
    //             <Link to="pricing">
    //               Pricing
    //             </Link>
    //           </li>
    //           <li>
    //             <a
    //
    //               href="https://connect2buyers.com/export.pdf"
    //               target="_blank"
    //             >
    //               Export Import
    //             </a>
    //           </li>
    //         </ul>
    //       </div>
    //     </div>

    //     <hr />

    //     <div className="row pb-md-6">
    //       <div className="col-sm-7 col-xs-12 align-self-center order-3 order-md-0">
    //         <p className="copy-right mb-0 pb-4 pb-md-0  text-white">
    //           Copyright &copy; 2019. All Rights Reserved, Parent company{" "}
    //           <a target="_blank" href="https://dgmt.in">
    //             DGMT (Digital Growth Marketing Technology)
    //           </a>
    //         </p>
    //       </div>

    //       <div className="col-sm-5 col-xs-12 d-flex align-items-center">
    //         <div className="ml-md-auto mx-auto mx-md-0 mt-3 mb-5 my-md-0">
    //           <a
    //             className="icon-sm icon-default icon-border hover-bg-primary rounded-circle ml-2"
    //             href="https://www.facebook.com/weareconnect2buyers/"
    //             target="_blank"
    //           >
    //             <i className="fa fa-facebook" aria-hidden="true"></i>
    //           </a>
    //           <a
    //             className="icon-sm icon-default icon-border hover-bg-primary rounded-circle ml-2"
    //             href="https://twitter.com/weconnect2buyers"
    //             target="_blank"
    //           >
    //             <i className="fa fa-twitter" aria-hidden="true"></i>
    //           </a>
    //           <a
    //             className="icon-sm icon-default icon-border hover-bg-primary rounded-circle ml-2"
    //             href="https://www.instagram.com/connect2buyers/"
    //             target="_blank"
    //           >
    //             <i className="fa fa-instagram" aria-hidden="true"></i>
    //           </a>
    //           <a className="icon-sm icon-default icon-border hover-bg-primary rounded-circle ml-2" href="https://www.linkedin.com/company/confirm-buyers/" target="_blank">
    //                             <i className="fa fa-linkedin" aria-hidden="true"></i>
    //                         </a>
    //           <a
    //             className="icon-sm icon-default icon-border hover-bg-primary rounded-circle ml-2"
    //             href="https://in.pinterest.com/weare/"
    //             target="_blank"
    //           >
    //             <i className="fa fa-pinterest" aria-hidden="true"></i>
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </footer>
  );
}

export default Footer;
