import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store, {persistor} from './store/store';
import { Provider } from 'react-redux';
import { Helmet } from "react-helmet";
import { PersistGate } from 'redux-persist/integration/react'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Helmet>
    {/* <script src="https://code.jquery.com/jquery-3.6.1.min.js"></script>
      <script src="/assets/plugins/bootstrap/js/bootstrap.bundle.js"></script> */}
      {/* <script> var jQuery=$; </script> */}
      {/* <script src="/assets/plugins/menuzord/js/menuzord.js"></script> */}
      {/* <script src="/assets/plugins/selectric/jquery.selectric.min.js"></script> */}
      {/* <script src="/assets/plugins/dzsparallaxer/dzsparallaxer.js"></script> */}
      {/* <script src="/assets/plugins/dzsparallaxer/dzsparallaxer.js"></script> */}
      {/* <script src="/assets/plugins/isotope/isotope.pkgd.min.js"></script> */}
      {/* <script src="/assets/plugins/revolution/js/jquery.themepunch.tools.min.js"></script> */}
      {/* <script src="/assets/plugins/revolution/js/jquery.themepunch.revolution.min.js"></script> */}
      {/* <script src="/assets/plugins/smoothscroll/SmoothScroll.js"></script> */}
      {/* <script src="/assets/plugins/owl-carousel/owl.carousel.min.js"></script> */}
      {/* <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDU79W1lu5f6PIiuMqNfT1C6M0e_lq1ECY"></script> */}
      {/* <script src="/assets/plugins/map/js/markerclusterer.js"></script> */}
      {/* <script src="/assets/plugins/map/js/rich-marker.js"></script> */}
      {/* <script src="/assets/plugins/map/js/infobox_packed.js"></script> */}
      {/* <script src="/assets/js/map.js"></script> */}
      {/* <script src="/assets/js/listty.js"></script> */}

      {/* <script src="/assets/options/optionswitcher.js"></script> */}
    </Helmet>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
