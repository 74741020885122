import React from "react";
import { Navigate, Outlet, Route, useNavigate } from "react-router-dom";
import PanelSidebar from "../components/common/panel-sidebar";
import PannelHeader from "../components/common/pannel-header";

export const ProtectedRoutes = () => {
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem("token");
  return (
    <div class="page-wrapper">
      <PanelSidebar />
      <div class="dash-content-wrap">
        <PannelHeader />
        <div class="dash-content">
          <div class="container-fluid">
            {isAuthenticated ? <Outlet /> : <Navigate to="/login" />}
          </div>
        </div>
        <div class="dash-footer">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="copyright">
                  <p>© 2024 Connect2Buyer . All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
