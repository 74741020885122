import { takeLatest } from "redux-saga/effects";
import {saga} from "./sagas";
import * as ActionTypes  from "../actions/actionTypes";

export function* watcherSaga() {
  yield takeLatest(ActionTypes.GET_CATEGORY, saga.handleGetCategory);
  yield takeLatest(ActionTypes.GET_TESTIMONIALS, saga.handleGetTestimonials);
  yield takeLatest(ActionTypes.GET_HOME_LISTING, saga.handleGetHomeListing);
  yield takeLatest(ActionTypes.GET_PACKAGE_REQUEST, saga.handleGetPackageRequest);
  yield takeLatest(ActionTypes.POST_PACKAGE_REQUEST, saga.handlePostPackageRequest);
  yield takeLatest(ActionTypes.GET_MAIN_CATEGORY, saga.handleGetMainCategory);
  yield takeLatest(ActionTypes.GET_SEARCH, saga.handleGetSearch);
  yield takeLatest(ActionTypes.GET_COUNTRY, saga.handleGetCountry);
  yield takeLatest(ActionTypes.LOGIN, saga.handleLogin);
  yield takeLatest(ActionTypes.REGISTER, saga.handleRegister);
  yield takeLatest(ActionTypes.LOGOUT, saga.handleLogout);
  yield takeLatest(ActionTypes.POST_CATEGORY, saga.handlePostCategory);
  yield takeLatest(ActionTypes.GET_CITY, saga.handleGetCity);
  yield takeLatest(ActionTypes.POST_CITY, saga.handlePostCity);
  yield takeLatest(ActionTypes.GET_LEADS, saga.handleGetLeads);
  yield takeLatest(ActionTypes.POST_LEADS, saga.handlePostLeads);
  yield takeLatest(ActionTypes.GET_STATE, saga.handleGetState);
  yield takeLatest(ActionTypes.POST_STATE, saga.handlePostState);
  yield takeLatest(ActionTypes.GET_PRODUCT, saga.handleGetProduct);
  yield takeLatest(ActionTypes.POST_PRODUCT, saga.handlePostProduct);
  yield takeLatest(ActionTypes.GET_AREA, saga.handleGetArea);
  yield takeLatest(ActionTypes.GET_COMP_PROFILE, saga.handleGetCompProfile);
  yield takeLatest(ActionTypes.POST_COMP_PROFILE, saga.handlePostCompProfile);
  yield takeLatest(ActionTypes.POST_REVIEWS, saga.handlePostReviews);
  yield takeLatest(ActionTypes.POST_USER_CATEGORY, saga.handlePostUserCategory);
  yield takeLatest(ActionTypes.LOADING_STATUS, saga.handleLoadingStatus);
}
