import Map from "./map";
import ListProd from "./list";
import SidebarProd from "./sidebar";
const ProductListing = () => {
    return (
        <>
            {/* <Map /> */}
            <section className="py-7 py-md-10">
                <div className="container">
                    <div className="row">
                        <ListProd />
                        <SidebarProd />
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProductListing;