import { call, put, delay } from "redux-saga/effects";
import { appService } from "../services/appServices";
import { actions } from "../actions/actions";
import { AlertService } from "../services/alertService";

function* handleGetCategory(action) {
  try {
    const response = yield call(appService.getCategory);
    // data.push(response.data);
    yield put(actions.setCategory(response.data));
  } catch (error) {
    console.log(error);
  }
}
function* handleGetTestimonials(action) {
  try {
    const response = yield call(appService.getTestimonials);
    // data.push(response.data);
    yield put(actions.setTestimonials(response.data));
  } catch (error) {
    console.log(error);
  }
}
function* handleGetHomeListing(action) {
  try {
    const response = yield call(appService.getCompProfileHome);
    // data.push(response.data);
    yield put(actions.setHomeListing(response.data));
  } catch (error) {
    console.log(error);
  }
}
function* handleGetPackageRequest(action) {
  try {
    const response = yield call(appService.getPackageRequest);
    // data.push(response.data);
    yield put(actions.setPackageRequest(response.data));
  } catch (error) {
    console.log(error);
  }
}
function* handleGetMainCategory(action) {
  try {
    const response = yield call(appService.getMainCategory);
    // data.push(response.data);
    yield put(actions.setMainCategory(response.data));
  } catch (error) {
    console.log(error);
  }
}
function* handleGetSearch(action) {
  try {
    const response = yield call(appService.getSearchData, action);
    // data.push(response.data);
    if (action.params.type == 2) {
      yield put(actions.setSearch(response.data));
    } else {
      yield put(actions.setCompProfile(response.data));
    }
  } catch (error) {
    console.log(error);
  }
}
function* handleGetCountry(action) {
  try {
    const response = yield call(appService.getCountry);
    // data.push(response.data);
    // console.log("+++++++++++++++++++++++", response);
    yield put(actions.setCountry(response));
  } catch (error) {
    console.log(error);
  }
}
function* handleGetCompProfile(action) {
  try {
    let param = action ? action : null;
    const response = yield call(appService.getCompProfile, param);
    console.log("fsdf", response);
    // data.push(response.data);
    yield put(actions.setCompProfile(response.data));
  } catch (error) {
    console.log(error);
  }
}
function* handleGetCity(action) {
  try {
    const response = yield call(appService.getCity);
    yield put(actions.setCity(response.data));
  } catch (error) {
    console.log(error);
  }
}
function* handleGetArea(action) {
  try {
    const response = yield call(appService.getArea);
    yield put(actions.setArea(response.data));
  } catch (error) {
    console.log(error);
  }
}
function* handleGetLeads(action) {
  try {
    const response = yield call(appService.getLeads);
    yield put(actions.setLeads(response.data));
  } catch (error) {
    console.log(error);
  }
}
function* handleGetState(action) {
  try {
    const response = yield call(appService.getState);
    yield put(actions.setState(response.data));
  } catch (error) {
    console.log(error);
  }
}

function* handleGetProduct(action) {
  try {
    const response = yield call(appService.getProduct);
    yield put(actions.setProduct(response.data));
  } catch (error) {
    console.log(error);
  }
}

function* handleLogin(action) {
  try {
    const response = yield call(appService.doLogin, action);
    delay(1000);
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("user", JSON.stringify(response.data.user));
    yield put(actions.setUserDetails(response.data.user));
  } catch (error) {
    console.log(error);
  }
}
function* handleRegister(action) {
  try {
    const response = yield call(appService.doRegister, action);
  } catch (error) {
    console.log(error);
  }
}
function* handlePostCategory(action) {
  try {
    const response = yield call(appService.postCategory, action);
    yield call(appService.getCategory);
  } catch (error) {
    console.log(error);
  }
}

function* handlePostPackageRequest(action) {
  try {
    const response = yield call(appService.postPackageRequest, action);
  } catch (error) {
    console.log(error);
  }
}

function* handlePostCity(action) {
  try {
    const response = yield call(appService.postCity, action);
    yield call(appService.getCategory);
  } catch (error) {
    console.log(error);
  }
}
function* handlePostLeads(action) {
  try {
    const response = yield call(appService.postLeads, action);
    // yield call(appService.getCategory);
  } catch (error) {
    console.log(error);
  }
}
function* handlePostUserCategory(action) {
  try {
    const response = yield call(appService.postUserCategory, action);
    // yield call(appService.getCategory);
  } catch (error) {
    console.log(error);
  }
}
function* handlePostState(action) {
  try {
    const response = yield call(appService.postState, action);
    yield call(appService.getCategory);
  } catch (error) {
    console.log(error);
  }
}
function* handlePostReviews(action) {
  try {
    const response = yield call(appService.postReviews, action);
  } catch (error) {
    console.log(error);
  }
}
function* handlePostProduct(action) {
  try {
    const response = yield call(appService.postProduct, action);
    yield call(appService.getCategory);
  } catch (error) {
    console.log(error);
  }
}

function* handlePostCompProfile(action) {
  try {
    const response = yield call(appService.postCompProfile, action);
    AlertService.errorAlert(response);
    yield call(appService.getCategory);
  } catch (error) {
    console.log(error);
  }
}

function* handleLogout(action) {
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    yield put(actions.setUserDetails({}));
  } catch (error) {
    console.log(error);
  }
}

function* handleLoadingStatus(status) {
  yield put(actions.setLoadingStatus(status));
}

export const saga = {
  handleLogin,
  handleGetCategory,
  handleLogout,
  handlePostCategory,
  handleGetCity,
  handlePostCity,
  handleGetState,
  handlePostState,
  handleGetProduct,
  handlePostProduct,
  handleGetArea,
  handleRegister,
  handlePostCompProfile,
  handleGetCompProfile,
  handleGetLeads,
  handlePostLeads,
  handlePostReviews,
  handlePostUserCategory,
  handleGetCountry,
  handleGetSearch,
  handleGetMainCategory,
  handleGetPackageRequest,
  handlePostPackageRequest,
  handleGetHomeListing,
  handleLoadingStatus,
  handleGetTestimonials,
};
