import React, { useEffect, useState } from "react";
import { appService } from "../../services/appServices";
import { useNavigate } from "react-router-dom";
import { AlertService } from "../../services/alertService";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../actions/actions";
import { Constants } from "../../contants";

const Listing = () => {
  const stateData = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(stateData?.compProfile ? stateData?.compProfile : []);

    // console.log(stateData?.compProfile.data)
    // alert(JSON.stringify(stateData?.compProfile.links))
  }, [JSON.stringify(stateData?.compProfile)]);
  const getProfile = async (selectedPage) => {
    let name = localStorage.getItem("searchby");
    let id = localStorage.getItem("searchid");
    if (name == "search") {
      let details = {};
      details["type"] = 1;
      details["term"] = id;
      details["page"] = selectedPage;
      await dispatch(actions.getSearch(details));
    } else {
      if (!name) {
        name = "all";
        id = 0;
      }
      dispatch(
        actions.getCompProfile({ name: name, id: id, page: selectedPage })
      );
    }
  };
  const goToDetails = (details) => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user?.role == "user") {
      localStorage.setItem("compDetails", JSON.stringify(details));
      setTimeout(() => {
        navigate("/listing/details");
      }, 500);
    } else {
      AlertService.infoAlert("Please Login to see Details");
      setTimeout(() => {
        navigate("/login");
      }, 500);
    }
  };

  return (
    <div className="filter-wrapper style1 section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <form className="hero__form v2 filter">
              <div className="row">
                <div className="col-lg-4 col-md-12">
                  <input
                    className="hero__form-input custom-select"
                    type="text"
                    name="place-event"
                    id="place-event"
                    placeholder="What are you looking for?"
                    style={{ display: "none" }}
                  />
                  <div
                    className="nice-select hero__form-input custom-select"
                    tabindex="0"
                  >
                    <span className="current"></span>
                    <ul className="list"></ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12">
                  <select
                    className="hero__form-input  custom-select"
                    style={{ display: "none" }}
                  >
                    <option>Select Location </option>
                    <option>New York</option>
                    <option>California</option>
                    <option>Washington</option>
                    <option>New Jersey</option>
                    <option>Florida</option>
                    <option>Los Angeles</option>
                  </select>
                  <div
                    className="nice-select hero__form-input custom-select"
                    tabindex="0"
                  >
                    <span className="current">Select Location </span>
                    <ul className="list">
                      <li
                        data-value="Select Location"
                        className="option selected"
                      >
                        Select Location{" "}
                      </li>
                      <li data-value="New York" className="option">
                        New York
                      </li>
                      <li data-value="California" className="option">
                        California
                      </li>
                      <li data-value="Washington" className="option">
                        Washington
                      </li>
                      <li data-value="New Jersey" className="option">
                        New Jersey
                      </li>
                      <li data-value="Florida" className="option">
                        Florida
                      </li>
                      <li data-value="Los Angeles" className="option">
                        Los Angeles
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12">
                  <select
                    className="hero__form-input  custom-select"
                    style={{ display: "none" }}
                  >
                    <option>Select Categories</option>
                    <option>Art's</option>
                    <option>Health</option>
                    <option>Hotels</option>
                    <option>Real Estate</option>
                    <option>Rentals</option>
                  </select>
                  <div
                    className="nice-select hero__form-input custom-select"
                    tabindex="0"
                  >
                    <span className="current">Select Categories</span>
                    <ul className="list">
                      <li
                        data-value="Select Categories"
                        className="option selected"
                      >
                        Select Categories
                      </li>
                      <li data-value="Art's" className="option">
                        Art's
                      </li>
                      <li data-value="Health" className="option">
                        Health
                      </li>
                      <li data-value="Hotels" className="option">
                        Hotels
                      </li>
                      <li data-value="Real Estate" className="option">
                        Real Estate
                      </li>
                      <li data-value="Rentals" className="option">
                        Rentals
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-12">
                  <div className="submit_btn text-right md-left">
                    <button className="btn v3  mar-right-5" type="submit">
                      <i className="ion-ios-search" aria-hidden="true"></i>{" "}
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-12">
            <div className="row pad-tb-50 align-items-center">
              <div className="col-lg-7">
                <div className="item-view-mode res-box">
                  {/* <!-- item-filter-list start --> */}
                  <ul className="nav item-filter-list" role="tablist">
                    <li>
                      <a className="active" data-toggle="tab" href="#grid-view">
                        <i className="ion-grid"></i>
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#list-view">
                        <i className="ion-ios-list"></i>
                      </a>
                    </li>
                  </ul>
                  {/* <!-- item-filter-list end --> */}
                </div>
              </div>
              <div className="col-lg-3 col-sm-8 col-12">
                <div className="item-element res-box  text-right xs-left">
                  <p>
                    Showing{" "}
                    <span>{`${data.from}-${data.to} of ${data.total}`}</span>{" "}
                    Listings
                  </p>
                </div>
              </div>
            </div>
            <div className="item-wrapper">
              <div className="tab-content">
                <div id="grid-view" className="tab-pane active  product-grid">
                  {data?.total == 0 && (
                    <div className="alert alert-info" role="alert">
                      <h4>No data found</h4>
                    </div>
                  )}
                  <div className="row">
                    {data &&
                      data?.data?.map((item) => (
                        <div className="col-xl-4 col-lg-6 col-md-12">
                          <div className="trending-place-item">
                            <div
                              className="trending-img"
                              style={{ height: "235px" }}
                            >
                              <img
                                src={`${Constants.COMP_IMG_URL}/comp/${item?.image}`}
                                alt="#"
                              />
                              {/* <span className="trending-rating-green">7</span> */}
                              <span className="save-btn">
                                <i className="icofont-heart"></i>
                              </span>
                            </div>
                            <div className="trending-title-box">
                              <h4>
                                <a href="#" onClick={() => goToDetails(item)}>
                                  {item?.title}
                                </a>
                              </h4>
                              <div className="customer-review">
                                <div className="rating-summary float-left">
                                  <div className="rating-result" title="60%">
                                    <ul className="product-rating">
                                      <li>
                                        <i className="ion-android-star"></i>
                                      </li>
                                      <li>
                                        <i className="ion-android-star"></i>
                                      </li>
                                      <li>
                                        <i className="ion-android-star"></i>
                                      </li>
                                      <li>
                                        <i className="ion-android-star-half"></i>
                                      </li>
                                      <li>
                                        <i className="ion-android-star-half"></i>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="review-summury float-right">
                                  {/* <p>
                                    <a href="#">3 Reviews</a>
                                  </p> */}
                                </div>
                              </div>
                              <ul className="trending-address">
                                <li>
                                  <i className="ion-ios-location"></i>
                                  <p>{item?.address}</p>
                                </li>
                                <li>
                                  <i className="ion-ios-telephone"></i>
                                  <p>{item?.phone}</p>
                                </li>
                                <li>
                                  <i className="ion-android-globe"></i>
                                  <p className="text-break">{item?.website}</p>
                                </li>
                              </ul>
                              <div className="trending-bottom mar-top-15 pad-bot-30">
                                <div className="trend-left float-left">
                                  {/* <span className="round-bg pink">
                                    <i className="icofont-hotel"></i>
                                  </span> */}
                                  <p>
                                    <a href="#">{item?.category?.name}</a>
                                  </p>
                                </div>
                                {/* <div className="trend-right float-right">
                                  <div className="trend-open">
                                    {"More >"}
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                {/* <div id="list-view" className="tab-pane product-list">
                  <div className="row trending-place-item">
                    <div className="col-md-6 no-pad-lr">
                      <div className="trending-img">
                        <img
                          src="/images/category/places/place-1.jpg"
                          alt="#"
                        />
                        <span className="trending-rating-green">7</span>
                        <span className="save-btn">
                          <i className="icofont-heart"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 no-pad-lr">
                      <div className="trending-title-box">
                        <h4>
                          <a href="single-listing-one.html">
                            Four Seasons Resort
                          </a>
                        </h4>
                        <div className="customer-review">
                          <div className="rating-summary float-left">
                            <div className="rating-result" title="60%">
                              <ul className="product-rating">
                                <li>
                                  <i className="ion-android-star"></i>
                                </li>
                                <li>
                                  <i className="ion-android-star"></i>
                                </li>
                                <li>
                                  <i className="ion-android-star"></i>
                                </li>
                                <li>
                                  <i className="ion-android-star-half"></i>
                                </li>
                                <li>
                                  <i className="ion-android-star-half"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="review-summury float-right">
                            <p>
                              <a href="#">3 Reviews</a>
                            </p>
                          </div>
                        </div>
                        <ul className="trending-address">
                          <li>
                            <i className="ion-ios-location"></i>
                            <p>4210 Khale Street,Florence,USA</p>
                          </li>
                          <li>
                            <i className="ion-ios-telephone"></i>
                            <p>+843-600-2040</p>
                          </li>
                          <li>
                            <i className="ion-android-globe"></i>
                            <p>www.laresorta.com</p>
                          </li>
                        </ul>
                        <div className="trending-bottom mar-top-15 pad-bot-30">
                          <div className="trend-left float-left">
                            <span className="round-bg pink">
                              <i className="icofont-hotel"></i>
                            </span>
                            <p>
                              <a href="#">Hotel</a>
                            </p>
                          </div>
                          <div className="trend-right float-right">
                            <div className="trend-open">
                              <i className="ion-clock"></i>
                              Open <p>till 11.00pm</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row trending-place-item">
                    <div className="col-md-6 no-pad-lr">
                      <div className="trending-img">
                        <img src="/images/category/places/cafe.jpg" alt="#" />
                        <span className="trending-rating-pink">9</span>
                        <span className="save-btn">
                          <i className="icofont-heart"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 no-pad-lr">
                      <div className="trending-title-box">
                        <h4>
                          <a href="single-listing-two.html">Cafe Intermezzo</a>
                        </h4>
                        <div className="customer-review">
                          <div className="rating-summary float-left">
                            <div className="rating-result" title="60%">
                              <ul className="product-rating">
                                <li>
                                  <i className="ion-android-star"></i>
                                </li>
                                <li>
                                  <i className="ion-android-star"></i>
                                </li>
                                <li>
                                  <i className="ion-android-star"></i>
                                </li>
                                <li>
                                  <i className="ion-android-star-half"></i>
                                </li>
                                <li>
                                  <i className="ion-android-star-half"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="review-summury float-right">
                            <p>
                              <a href="#">5 Reviews</a>
                            </p>
                          </div>
                        </div>
                        <ul className="trending-address">
                          <li>
                            <i className="ion-ios-location"></i>
                            <p>2721 Andy Street,ELLSWORTH,USA</p>
                          </li>
                          <li>
                            <i className="ion-ios-telephone"></i>
                            <p>+605-344-1198</p>
                          </li>
                          <li>
                            <i className="ion-android-globe"></i>
                            <p>www.cafemezzo.com</p>
                          </li>
                        </ul>

                        <div className="trending-bottom mar-top-15 pad-bot-30">
                          <div className="trend-left float-left">
                            <span className="round-bg green">
                              <i className="icofont-tea"></i>
                            </span>
                            <p>
                              <a href="#">Cafe</a>
                            </p>
                          </div>

                          <div className="trend-right float-right">
                            <div className="trend-closed">Closed</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row trending-place-item">
                    <div className="col-md-6 no-pad-lr">
                      <div className="trending-img">
                        <img
                          src="/images/category/places/place-5.jpg"
                          alt="#"
                        />
                        <span className="trending-rating-orange">6.5</span>
                        <span className="save-btn">
                          <i className="icofont-heart"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 no-pad-lr">
                      <div className="trending-title-box">
                        <h4>
                          <a href="single-listing-one.html">
                            Lagoon Theme Park
                          </a>
                        </h4>
                        <div className="customer-review">
                          <div className="rating-summary float-left">
                            <div className="rating-result" title="60%">
                              <ul className="product-rating">
                                <li>
                                  <i className="ion-android-star"></i>
                                </li>
                                <li>
                                  <i className="ion-android-star"></i>
                                </li>
                                <li>
                                  <i className="ion-android-star"></i>
                                </li>
                                <li>
                                  <i className="ion-android-star-half"></i>
                                </li>
                                <li>
                                  <i className="ion-android-star-half"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="review-summury float-right">
                            <p>
                              <a href="#">3 Reviews</a>
                            </p>
                          </div>
                        </div>
                        <ul className="trending-address">
                          <li>
                            <i className="ion-ios-location"></i>
                            <p>1301 Avenue, Brooklyn, NY 11230</p>
                          </li>
                          <li>
                            <i className="ion-ios-telephone"></i>
                            <p>+44 20 7336 8898</p>
                          </li>
                          <li>
                            <i className="ion-android-globe"></i>
                            <p>www.burgerandlobster.com</p>
                          </li>
                        </ul>

                        <div className="trending-bottom mar-top-15 pad-bot-30">
                          <div className="trend-left float-left">
                            <span className="round-bg red">
                              <i className="icofont-travelling"></i>
                            </span>
                            <p>
                              <a href="#">Travel</a>
                            </p>
                          </div>
                          <div className="trend-right float-right">
                            <div className="trend-open">
                              <i className="ion-clock"></i>
                              Open <p>till 10.00pm</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row trending-place-item">
                    <div className="col-md-6 no-pad-lr">
                      <div className="trending-img">
                        <img
                          src="/images/category/places/place-4.jpg"
                          alt="#"
                        />
                        <span className="trending-rating-pink">8</span>
                        <span className="save-btn">
                          <i className="icofont-heart"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 no-pad-lr">
                      <div className="trending-title-box">
                        <h4>
                          <a href="single-listing-one.html">The Katikies</a>
                        </h4>
                        <div className="customer-review">
                          <div className="rating-summary float-left">
                            <div className="rating-result" title="60%">
                              <ul className="product-rating">
                                <li>
                                  <i className="ion-android-star"></i>
                                </li>
                                <li>
                                  <i className="ion-android-star"></i>
                                </li>
                                <li>
                                  <i className="ion-android-star"></i>
                                </li>
                                <li>
                                  <i className="ion-android-star-half"></i>
                                </li>
                                <li>
                                  <i className="ion-android-star-half"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="review-summury float-right">
                            <p>
                              <a href="#">3 Reviews</a>
                            </p>
                          </div>
                        </div>
                        <ul className="trending-address">
                          <li>
                            <i className="ion-ios-location"></i>
                            <p>1301 Avenue, Brooklyn, NY 11230</p>
                          </li>
                          <li>
                            <i className="ion-ios-telephone"></i>
                            <p>+44 20 7336 8898</p>
                          </li>
                          <li>
                            <i className="ion-android-globe"></i>
                            <p>www.burgerandlobster.com</p>
                          </li>
                        </ul>

                        <div className="trending-bottom mar-top-15 pad-bot-30">
                          <div className="trend-left float-left">
                            <span className="round-bg pink">
                              <i className="icofont-hotel"></i>
                            </span>
                            <p>
                              <a href="#">Hotel</a>
                            </p>
                          </div>
                          <div className="trend-right float-right">
                            <div className="trend-open">
                              <i className="ion-clock"></i>
                              Open <p>till 10.00pm</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <!--pagination starts--> */}
                <div className="post-nav nav-res pad-top-10">
                  <div className="row">
                    <div className="col-md-8 offset-md-2  col-xs-12 ">
                      <div className="page-num text-center">
                        <ul>
                          {data?.links?.map((link,index) => (
                            <li className="active">
                              <a
                                href={"#"}
                                onClick={() => getProfile(link.label)}
                              >
                                {index==0 && "<"} {data?.links.length-1==index && ">"}
                                {index>0 && data?.links.length-1>index && link.label}
                              </a>
                              {/* <a href={"#"}>{link.label}</a> */}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--pagination ends--> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listing;
