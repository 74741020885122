
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { actions } from "../../actions/actions";
const CityBusinessDirectory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateData = useSelector((state) => state);
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [cityList, setCityList] = useState("");
  const [stateList, setStateList] = useState("");
  const [region, setRegion] = useState("");
  const [countryList, setCountryList] = useState(stateData.country);
  const [cityStateListing, setcityStateListing] = useState([]);
  const [listType, setlistType] = useState();
  const getState = (id) => {
    let index = countryList.findIndex((x) => x.id == id);
    setStateList(countryList[index].state);
    setcityStateListing(countryList[index].state);
    setlistType("area");
  };

  const getCity = (id) => {
    let index = stateList.findIndex((x) => x.id == id);
    setCityList(stateList[index].city);
    setcityStateListing(stateList[index].city);
    setlistType("city");
  };
  const fetchListing = (id) => {
    dispatch(
      actions.getCompProfile({ name: listType, id: id ? id : "x", page: 0 })
    );
    setTimeout(() => {
        
        navigate("/listing");
    }, 500);
  };
  return (
    <>
      {/* <Map /> */}
      {/* <Page /> */}
      <section className="py-10">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-6">
              <div className="form-group mb-6">
                <h6>Listing Country</h6>
                <div className="select-default bg-white">
                  <select
                    className="hero__form-input custom-select"
                    onChange={(e) => {
                      setCountry(e.target.value);
                      getState(e.target.value);
                    }}
                  >
                    <option value="">Select Country</option>
                    {(countryList ? countryList : []).map((country) => {
                      return <option value={country.id}>{country.name}</option>;
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-6">
                <h6>Listing State</h6>
                <div className="select-default bg-white">
                  <select
                    className="hero__form-input custom-select"
                    onChange={(e) => {
                      setState(e.target.value);
                      getCity(e.target.value);
                    }}
                  >
                    <option value="">Select State</option>
                    {(stateList ? stateList : []).map((state) => {
                      return <option value={state.id}>{state.name}</option>;
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {cityStateListing ? (
              <div className="col-md-12 text-center">
                <h4 className="text-center">Select country to get state</h4>
              </div>
            ) : (
              ""
            )}
            {(cityStateListing ? cityStateListing : []).map((list) => {
              return (
                <div className="col-md-3 business-directory-city-list">
                  <Link  onClick={()=>fetchListing(list.id,)}>
                    <h6>{list.name}</h6>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default CityBusinessDirectory;
