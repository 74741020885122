import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../actions/actions';
import HorizontalMenu from '../../horizontalMenu';
import Heading from '../../heading';

const Category = props => {
    const stateData = useSelector((state) => state);
    const dispatch = useDispatch();
    const [category, setCategory] = useState("");
    const [mainCategory, setMainCategory] = useState("");
    let details = {};

    const pushCategory = () => {
        details["name"] = category;
        details["main_cat_id"] = mainCategory;
        details["dispatch"] = dispatch;
        dispatch(actions.postCategory(details))
    }
    return (
        <>
            <HorizontalMenu  list="/category/list" add="/category/add" />
            <section class="bg-light py-5 height100vh">
                <div class="container">
                    <Heading heading="Add Category" />
                    <div class="card">
                        <div className="card-body px-7 pt-7 pb-0">
                            <div className="form-group mb-7">
                                <label htmlFor="exampleInputText">Main Category*</label>
                                <select className='form-control' onChange={(e) => setMainCategory(e.target.value)}>
                                    <option>Select Main Category</option>
                                {(stateData.mainCategories ? stateData.mainCategories : []).map((mainCategory) => {
                                   return <option value={mainCategory.id}>{mainCategory.name}</option>
                                })}
                                </select>
                                <em>Enter main category.</em>
                            </div>
                            <div className="form-group mb-7">
                                <label htmlFor="exampleInputText">Category*</label>
                                <input type="text" className="form-control"
                                    onChange={(e) => setCategory(e.target.value)}
                                    aria-describedby="emailHelp" />
                                <em>Enter category.</em>
                            </div>
                            <div className="form-group d-flex justify-content-between align-items-center mb-7">
                                <button type="button" className="btn btn-outline-primary text-uppercase" onClick={() => pushCategory()}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};


export default Category;