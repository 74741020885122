import React from 'react';
import { Link } from 'react-router-dom';

const HorizontalMenu = props => {
    return (
        <nav class="navbar navbar-expand-md navbar-dark">
        <div class="container">

          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item">
                <Link class="nav-link " to={props.list?props.list:"#"}>
                  <i class="fa fa-list" aria-hidden="true"></i> List <span class="sr-only">(current)</span></Link>
              </li>

              <li class="nav-item">
                <Link class="nav-link " to={props.add}>
                  <i class="fa fa-plus" aria-hidden="true"></i> Add  </Link>
              </li>

            </ul>

            {/* <form class="form-inline my-2 my-lg-0 position-relative d-none d-md-block">
              <input class="form-control-sm" placeholder="Search" aria-label="Search">
              <i class="fa fa-search" aria-hidden="true"></i>
            </form> */}
          </div>
        </div>
      </nav>
    );
};


export default HorizontalMenu;