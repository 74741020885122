
import PageTitle from "../common/page-title";
import Category from "../home/categories";
import $ from 'jquery'
const AllCategories = () => {
    
    return (
        <>
            {/* <Map /> */}
            <PageTitle title="All Categories" />
            <section className="">
                <div className="container">
                    <div className="row">
                        <Category showCatCount={150} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default AllCategories;