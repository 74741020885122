import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../actions/actions";
import { Link, useNavigate } from "react-router-dom";

const CategoryWithImage = (props) => {
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const requestList = (selectedPage, name, id) => {
    console.log("a")
    localStorage.setItem("searchby",name);
localStorage.setItem("searchid",id);
    dispatch(actions.getCompProfile({ name: name, id: id, page: selectedPage }))
    navigate('listing');
}
  return (
    <div className="col-3">
      <div className="category">
        <div className="item">
          <div className="box">
            <div className="heading">
              <div>
                <a href="#">
                  <h6 className="title">{props?.data?.name}</h6>
                </a>
              </div>
              <div>
                <a href="#" className="view-all">
                  View All
                </a>
              </div>
            </div>
            <ul className="box-inner">
              {props?.data?.category?.map((item, index) => (
                <>
                  {index <= 3 && (
                    <li>
                      <div className="pointer"  onClick={() => requestList(0, 'cat', item.id)}>
                        <figure>
                          <img
                            src="https://cdn.d4tcdn.com/staticdt/images/home-v1/agriculture/food-grains.jpg"
                            alt="Explore Food Grains"
                            width="135"
                            height="135"
                          />
                        </figure>
                        <div className="title">{item?.name}</div>
                      </div>
                    </li>
                  )}
                </>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryWithImage;
