import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../actions/actions';
import HorizontalMenu from '../../horizontalMenu';
import Heading from '../../heading';

const City = props => {
    const stateData=useSelector((state)=>state);
    const dispatch = useDispatch();
    const [city, setCity] = useState("");
    const [countryId, setCountryId] = useState(1);
    const [stateId, setStateId] = useState(1);
    let details = {};

    const pushCity = () => {
        details["name"] = city;
        details["country_id"] = countryId;
        details["state_id"] = stateId;
        dispatch(actions.postCity(details))
    }
    return (
        <>
            <HorizontalMenu list="/city/list" add="/city/add" />
            <section class="bg-light py-5 height100vh">
                <div class="container">
                    <Heading heading="Add City" />
                    <div class="card">
                        <div className="card-body px-7 pt-7 pb-0">
                            <div className="form-group mb-7">
                            <label htmlFor="exampleInputText">State*</label>
                            <select className="select-location form-control"
                                    onChange={(e) => setStateId(e.target.value)}>
                                <option>Select State</option>
                                {(stateData.state1 ? stateData.state1 : []).map((state) => {
                                        return <option value={state.id}>{state.name}</option>
                                    })
                                    }
                            </select>
                            </div>
                            <div className="form-group mb-7">
                                <label htmlFor="exampleInputText">City*</label>
                                <input type="text" className="form-control"
                                    onChange={(e) => setCity(e.target.value)}
                                    aria-describedby="emailHelp" />
                                <em>Enter city.</em>
                            </div>
                            <div className="form-group d-flex justify-content-between align-items-center mb-7">
                                <button type="button" className="btn btn-outline-primary text-uppercase" onClick={() => pushCity()}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};


export default City;