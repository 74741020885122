import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const PanelSidebar = () => {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState("");
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    let user = JSON.parse(localStorage.getItem("user"));
    let userRole = user ? user.role : "";
    setRole(userRole);
  }, []);

  return (
    <aside class="menu-sidebar js-right-sidebar d-block d-lg-none">
      <div class="logo">
        <Link to="#">
          <img src="images/logo-black.png" alt="Listagram" />
        </Link>
      </div>
      <div class="menu-sidebar__content js-scrollbar2">
        <div class="account-img">
          <img src="images/clients/reviewer-4.png" alt="Steve Austin" />
        </div>
        <h4 class="name">Howdy, {user?.name}</h4>
        <nav class="navbar-sidebar2">
          <ul class="list-unstyled navbar__list">
            {role == "Admin" ? (
              <>
                <li>
                  <Link to="admin/company/list">Company Listing</Link>
                </li>
                <li>
                  <Link to="category/list">Category</Link>
                </li>
                <li>
                  <Link to="subcategory/list">Sub Category</Link>
                </li>
                <li>
                  <Link to="microcategory/list">Micro Category</Link>
                </li>
                <li>
                  <Link to="state/list">State</Link>
                </li>
                <li>
                  <Link to="city/list">City</Link>
                </li>
                <li>
                  <Link to="admin/packagereq/list">Package Requests</Link>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to="listing/add">Add Listing</Link>
                </li>
                <li>
                  <Link to="product/add">Add Product</Link>
                </li>
              </>
            )}
            <li>
              <Link to="#">
                Logout
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default PanelSidebar;
