import { createStore, applyMiddleware } from "redux";
import reducer from "../reducers";
import createSagaMiddleware from "redux-saga";
import { watcherSaga } from "../sagas/rootSaga";
import { GET_AREA, GET_CATEGORY, GET_COMP_PROFILE, GET_COUNTRY, GET_HOME_LISTING, GET_LEADS, GET_MAIN_CATEGORY, GET_PACKAGE_REQUEST, GET_TESTIMONIALS } from "../actions/actionTypes";
import { actions } from "../actions/actions";
import { customMiddleware } from "../middleware/authCheckMiddleware";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware, customMiddleware.authCheckMiddleware];
const persistConfig = {
    key: 'root',
    storage,
}
const persistedReducer = persistReducer(persistConfig, reducer)
const store = createStore(persistedReducer, {}, applyMiddleware(...middleware));
sagaMiddleware.run(watcherSaga);
let persistor = persistStore(store)
// store.dispatch(actions.getFeeds([0,10]));
setTimeout(() => {
    store.dispatch({ type: GET_CATEGORY });
    store.dispatch({ type: GET_TESTIMONIALS });
    store.dispatch({ type: GET_HOME_LISTING });
    store.dispatch({ type: GET_MAIN_CATEGORY });
    store.dispatch({ type: GET_LEADS });
    // store.dispatch({type:GET_CITY});
    // store.dispatch({type:GET_STATE});
    
    store.dispatch({ type: GET_AREA });
    store.dispatch({ type: GET_COMP_PROFILE });
    store.dispatch({ type: GET_PACKAGE_REQUEST });
}, 1000);
setTimeout(() => {
    store.dispatch({ type: GET_COUNTRY });
}, 4000);

export default store;
export {persistor};
