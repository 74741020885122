import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { actions } from '../../actions/actions';

const ReviewsForm = props => {
    const [review,setReview]=useState("");
    const dispatch=useDispatch();
    var details={};
    const postReview=()=>{
        details['comp_id']=props.user_id;
        details['comment']=review;
        details['rating']=3.5;
        dispatch(actions.postReviews(details))
    }
    return (
        <div className="my-6" id="add-review">
                                <h3 className="font-weight-normal mb-6"> Add Review </h3>
                                <span className="star add-rating-default pl-0 mb-6"></span>
                                <form>
                                    {/* <div className="form-check form-check-inline mb-6 mr-lg-7">
                                        <input className="form-check-input mr-2" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"
                                            checked />
                                        <label className="form-check-label" for="inlineRadio1">
                                            <div className="media media-xs align-items-center">
                                                <img src="/assets/img/user/user-xs-3.jpg" className="mr-2 media-object rounded-circle" alt="Image User" />
                                                <div className="media-body">
                                                    <h5 className="media-heading mb-0">William</h5>
                                                </div>
                                            </div>
                                        </label>
                                    </div> */}
                                    {/* <div className="form-check form-check-inline mb-6">
                                        <input className="form-check-input mr-2" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                                        <label className="form-check-label" for="inlineRadio2">
                                            <div className="media media-xs align-items-center">
                                                <img src="/assets/img/user/avatar-01.png" className="mr-2 media-object rounded-circle border" alt="Image User" />
                                                <div className="media-body">
                                                    <h5 className="media-heading mb-0">Anonymous</h5>
                                                </div>
                                            </div>
                                        </label>
                                    </div> */}
                                    <div className="form-group mb-6">
                                        <textarea className="form-control" rows="6" placeholder="Enter Your Comment" onChange={e=>setReview(e.target.value)}></textarea>
                                    </div>
                                    <div className="form-group">
                                        <button type="button" className="btn btn-primary text-uppercase" onClick={()=>postReview()}> Send Review </button>
                                    </div>
                                </form>

                            </div>
    );
};

ReviewsForm.propTypes = {
    
};

export default ReviewsForm;