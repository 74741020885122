export const LOGIN="login";
export const REGISTER="register";
export const LOGOUT="logout";
export const GET_CATEGORY="getCategory";
export const SET_CATEGORY="setCategory";
export const POST_CATEGORY="postCategory";
export const GET_CITY="getCity";
export const SET_CITY="setCity";
export const POST_CITY="postCity";
export const SET_USER="setUser";
export const GET_USER="getUser";
export const GET_STATE="getState";
export const SET_STATE="setState";
export const POST_STATE="postState";
export const GET_PRODUCT="getProduct";
export const SET_PRODUCT="setProduct";
export const POST_PRODUCT="postProduct";
export const GET_AREA="getArea";
export const SET_AREA="setArea";
export const POST_AREA="postArea";
export const GET_COMP_PROFILE="getCompProfile";
export const SET_COMP_PROFILE="setCompProfile";
export const POST_COMP_PROFILE="postCompProfile";
export const GET_LEADS="getLeads";
export const SET_LEADS="setLeads";
export const POST_LEADS="postLeads";
export const POST_REVIEWS="postReviews";
export const GET_USER_CATEGORY="getUserCategory";
export const SET_USER_CATEGORY="setUserCategory";
export const POST_USER_CATEGORY="postUserCategory";
export const GET_COUNTRY="getCountry";
export const SET_COUNTRY="setCountry";
export const GET_SEARCH="getSearch";
export const SET_SEARCH="setSearch";
export const GET_MAIN_CATEGORY="getMainCategory";
export const SET_MAIN_CATEGORY="setMainCategory";
export const GET_PACKAGE_REQUEST="getPackageRequest";
export const SET_PACKAGE_REQUEST="setPackageRequest";
export const POST_PACKAGE_REQUEST="postPackageRequest";
export const GET_HOME_LISTING="getHomeListing";
export const SET_HOME_LISTING="setHomeListing";
export const LOADING_STATUS="loadingStatus";
export const SET_LOADING_STATUS="setLoadingStatus";
export const GET_TESTIMONIALS="getTestimonials";
export const SET_TESTIMONIALS="setTestimonials";