import * as actionTypes from "../actions/actionTypes"
let initialState = {}




export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CATEGORY:
      const { categories } = action;
      return { ...state, categories };
    case actionTypes.SET_HOME_LISTING:
      const { homeListing } = action;
      return { ...state, homeListing };
    case actionTypes.SET_PACKAGE_REQUEST:
      const { packageRequest } = action;
      return { ...state, packageRequest };
    case actionTypes.SET_MAIN_CATEGORY:
      const { mainCategories } = action;
      return { ...state, mainCategories };
    case actionTypes.SET_SEARCH:
      const { search } = action;
      return { ...state, search };
    case actionTypes.SET_COUNTRY:
      const { country } = action;
      return { ...state, country };
    case actionTypes.SET_USER:
      const { userDetails } = action;
      return { ...state, userDetails };
    case actionTypes.SET_PRODUCT:
      const { product } = action;
      return { ...state, product };
    case actionTypes.SET_CITY:
      const { city } = action;
      return { ...state, city };
    case actionTypes.SET_STATE:
      const { state1 } = action;
      return { ...state, state1 };
    case actionTypes.SET_AREA:
      const { area } = action;
      return { ...state, area };
    case actionTypes.SET_LEADS:
      const { leads } = action;
      return { ...state, leads };
    case actionTypes.SET_COMP_PROFILE:
      const { compProfile } = action;
      return { ...state, compProfile };
    case actionTypes.SET_TESTIMONIALS:
      const { testimonials } = action;
      return { ...state, testimonials };
    case actionTypes.SET_LOADING_STATUS:
      const { loadingStatus } = action;
      return { ...state, loadingStatus };

    default:
      return state;
  }
}


