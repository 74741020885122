// const API_URL = "api/";
const COMP_IMG_URL = "https://www.codeminer.in/confirm_buyer/backend/storage/app/public/";
const API_URL="https://www.codeminer.in/confirm_buyer/backend/public/api/";
// const API_URL = "https://confirmbuyers.com/backend/public/api/";
const BASE_ROUTER = "/next";
const STORAGE_LINK="hhttps://www.codeminer.in/confirm_buyer/backend/storage/app/";
// const BASE_ROUTER="/";

export const Constants = {
  API_URL,
  BASE_ROUTER,
  COMP_IMG_URL,
  STORAGE_LINK,
};
