import React from "react";

const SuppliersCountry = () => {
  return (
    <div className="hero-catagory mar-top-30 section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2 text-center">
            <h2 className="section-title v1">Find Suppliers from Top Countries</h2>
          </div>
          <div className="col-md-12">
            {/* <div className="swiper-container popular-place-wrap"> */}
              <div className="hero-catagory-wrapper v1">
                {[
                  { id: 706, city: "India" },
                  { id: 1126, city: "USA" },
                  // { id: 5022, city: "Noida" },
                  { id: 3659, city: "UK" },
                  // { id: 1168, city: "Panipat" },
                  { id: 5583, city: "UAE" },
                  { id: 2707, city: "Australia" },
                ].map((item) => {
                  return (
                      <a
                        href={`/listing/city/${item.id}/1`}
                        className="hero-category-content v1"
                      >
                        <i className="icofont-hotel"></i>
                        <p className="name">{item.city}</p>
                        <p className="d-name">{item.city}</p>
                      </a>
                  );
                })}
                 <a
                        href={`#`}
                        className="hero-category-content v1"
                      >
                        <i className="icofont-hotel"></i>
                        <p className="name">More</p>
                        <p className="d-name">More</p>
                      </a>
              </div>
            {/* </div> */}
            {/* <div className="slider-btn v1 popular-next">
              <i className="ion-arrow-right-c"></i>
            </div>
            <div className="slider-btn v1 popular-prev">
              <i className="ion-arrow-left-c"></i>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuppliersCountry;
