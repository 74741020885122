import jwt_decode from "jwt-decode";
import store from "../store/store";
import { actions } from "../actions/actions";
import { AlertService } from "../services/alertService";

const authCheckMiddleware = store => next => action => {
    const token =localStorage.getItem('token');
   
    if(token){
        
    if (jwt_decode(token).exp < Date.now() / 1000) {
        AlertService.infoAlert("Your session expired, please login again");
        localStorage.clear();
        store.dispatch(actions.logout());
      next(action);
      
      
    }
}
    next(action);

  };

  export const customMiddleware={
    authCheckMiddleware
  }