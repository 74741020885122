import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../actions/actions';

const PostRequirement = props => {
    const dispatch=useDispatch();
    const stateData = useSelector((state) => state);
    const [requirement, setRequirement] = useState("");
    const [name, setName] = useState("");
    const [unit, setUnit] = useState("");
    const [unit1, setUnit1] = useState("");
    const [quantity, setQuantity] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [cityList, setCityList] = useState("");
    const [stateList, setStateList] = useState("");
    const [countryList, setCountryList] = useState(stateData.country);

    const getState=(id)=>{
        let index=countryList.findIndex(x=>x.id==id);
        setStateList(countryList[index].state);
    }

    const getCity=(id)=>{
        let index=stateList.findIndex(x=>x.id==id);
        setCityList(stateList[index].city);
    }

    useEffect(()=>{
        //dispatch(actions.getCountry())
        setCountryList(stateData.country);
        console.log("mkf",JSON.stringify(countryList))
    },[JSON.stringify(stateData.country)])

    var details = {};
    var social = [];
    const pushRequirement = () => {
        details['name'] =name;
        details['requirement'] =requirement;
        details['unit'] =unit+"-"+unit1;
        details['preference'] =1;
        details['quantity'] =quantity;
        details['phone'] =phone;
        details['email'] =email;
        details['city'] =city;
        details['country_id'] =country;
        details['state_id'] =state;
        
        dispatch(actions.postLeads(details));
    }
    return (
        <section class="bg-light py-5 height100vh">
            <div class="container">
                <nav class="bg-transparent breadcrumb breadcrumb-2 px-0 mb-5" aria-label="breadcrumb">
                    <h2 class="font-weight-normal mb-4 mb-md-0">Let Us Know What You Need</h2>
                </nav>

                <form>
                    <div class="card">
                        <div class="card-body px-6 pt-6 pb-1">
                            <p class="mb-5">Just complete these simple steps. Get Instant quotes from Verified Suppliers</p>
                                <div class="row">
                                    <div class="form-group col-md-12 mb-6">
                                        <label htmlFor="listingTitle">Product/Services</label>
                                        <input type="text" placeholder="Products / Services you are looking for" class="form-control" onChange={e => setRequirement(e.target.value)} />
                                    </div>

                                    <div class="form-group col-md-6 mb-6">
                                        <label htmlFor="exampleFormControlSelect1">Quantity</label>
                                        <div class="select-default bg-white">
                                            <input type="text" class="form-control" id="addTags" placeholder="Estimated Order Quantity" onChange={e => setQuantity(e.target.value)} />
                                        </div>
                                    </div>

                                    <div class="form-group col-md-3 mb-6">
                                        <label htmlFor="discribeTheListing">Min Order Value</label>
                                        <input type="text" class="form-control" id="addTags" placeholder="Estimated Order Value" onChange={e => setUnit(e.target.value)} />
                                    </div>
                                    <div class="form-group col-md-3 mb-6">
                                        <label htmlFor="discribeTheListing">Max Order Value</label>
                                        <input type="text" class="form-control" id="addTags" placeholder="Estimated Order Value" onChange={e => setUnit1(e.target.value)} />
                                    </div>
                                </div>

                        </div>
                    </div>
                    <div class="card">
                    <div class="card-body px-6 pt-6 pb-1">
                            <h3 class="h4 mb-4">Contact Details</h3>
                            
                            <div class="row">
                            
                            <div class="form-group col-md-6 mb-6">
                                <div class="form-group mb-6">
                                        <label htmlFor="listingPhone">Full Name</label>
                                        <input type="phone" class="form-control" placeholder="Enter your name" onChange={e => setName(e.target.value)} />
                                    </div>
                                </div>
                                <div class="form-group col-md-6 mb-6">
                                <div class="form-group mb-6">
                                        <label htmlFor="listingPhone">Phone</label>
                                        <input type="phone" class="form-control" placeholder="546 - 545 - 2546" onChange={e => setPhone(e.target.value)} />
                                    </div>
                                </div>
                                <div class="form-group col-md-6 mb-6">
                                    <div class="form-group mb-6">
                                        <label htmlFor="listingEmail">Email</label>
                                        <input type="email" class="form-control" placeholder="hello@example.com" onChange={e => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                <div class="form-group col-md-6 mb-6">
                                    <div class="form-group mb-6">
                                        <label htmlFor="exampleFormControlSelect1">Country</label>
                                        <div class="select-default bg-white">
                                            <select class="select-location form-control" onChange={e =>{ setCountry(e.target.value)
                                            getState(e.target.value)
                                            }}>
                                                <option value="">Select Country</option>
                                                {(countryList ? countryList : []).map((country) => {
                                                    return <option value={country.id}>{country.name}</option>
                                                })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6 mb-6">
                                    <div class="form-group mb-6">
                                        <label htmlFor="exampleFormControlSelect1">State</label>
                                        <div class="select-default bg-white">
                                            <select class="select-location form-control" onChange={e => {
                                                setState(e.target.value)
                                                getCity(e.target.value)
                                                }}>
                                                <option value="">Select State</option>
                                                {(stateList ? stateList : []).map((state) => {
                                                    return <option value={state.id}>{state.name}</option>
                                                })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6 mb-6">
                                    <div class="form-group mb-6">
                                        <label htmlFor="exampleFormControlSelect1">City</label>
                                        <div class="select-default bg-white">
                                            <select class="select-location form-control" onChange={e => setCity(e.target.value)}>
                                                <option value="">Select City</option>
                                                {(cityList ? cityList : []).map((city) => {
                                                    return <option value={city.id}>{city.name}</option>
                                                })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    

                    <div class="row justify-content-center">
                        <div class="col-md-7 col-lg-6 col-xl-5">
                            <div class="mb-6">
                                <button type="button" class="btn btn-lg btn-primary btn-block" onClick={()=>pushRequirement()}>submit</button>
                            </div>
                        </div>
                    </div>

                </form>

            </div>
        </section>
    );
};

export default PostRequirement;