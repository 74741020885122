import React from "react";
import QueryForm from "./queryForm";
import Review from './review';
import ReviewsForm from './reviewsForm';

const CompanyDetails = () => {
  var compData = JSON.parse(localStorage.getItem("compDetails"));
  var tags = compData?.tag ? compData?.tag.split(",") : [];
  var timings = compData?.timings && JSON.parse(compData?.timings);
  return (
    <>
      <div
        class="listing-details-wrapper bg-h"
        style={{
          backgroundImage: "url(/images/single-listing/single-list-1.jpg)",
        }}
      >
        <div class="overlay op-3"></div>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="list-details-title v1">
                <div class="row">
                  <div class="col-lg-6 col-md-7 col-sm-12">
                    <div class="single-listing-title float-left">
                      <p>
                        <a href="#" class="btn v6">
                          {compData?.category?.name}
                        </a>
                      </p>
                      <h2>
                        {compData?.title} <i class="icofont-tick-boxed"></i>
                      </h2>
                      <p>
                        <i class="ion-ios-location"></i> {compData?.address}
                      </p>
                      <div class="list-ratings">
                        <span class="ion-android-star"></span>
                        <span class="ion-android-star"></span>
                        <span class="ion-android-star"></span>
                        <span class="ion-android-star"></span>
                        <span class="ion-android-star-half"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-5 col-sm-12">
                    <div class="list-details-btn text-right sm-left">
                      <div class="save-btn">
                        <a href="#" class="btn v3 white">
                          <i class="ion-heart"></i> Save
                        </a>
                      </div>
                      <div class="share-btn">
                        <a href="#" class="btn v3 white">
                          <i class="ion-android-share-alt"></i> Share
                        </a>
                        <ul class="social-share">
                          <li class="bg-fb">
                            <a href="#">
                              <i class="ion-social-facebook"></i>
                            </a>
                          </li>
                          <li class="bg-tt">
                            <a href="#">
                              <i class="ion-social-twitter"></i>
                            </a>
                          </li>
                          <li class="bg-ig">
                            <a href="#">
                              <i class="ion-social-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="list-details-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div id="list-menu" className="list_menu">
                <ul className="list-details-tab fixed_nav">
                  <li className="nav-item active">
                    <a href="#overview" className="active">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#gallery">Gallery</a>
                  </li>
                  {/* <li className="nav-item">
                    <a href="#price">Pricing</a>
                  </li> */}
                  <li className="nav-item">
                    <a href="#reviews">Reviews</a>
                  </li>
                  <li className="nav-item">
                    <a href="#add_review">Add Review</a>
                  </li>
                </ul>
              </div>
              {/*Listing Details starts*/}
              <div className="list-details-wrap">
                <div id="overview" className="list-details-section">
                  <h4>Overview</h4>
                  <div className="overview-content">
                    <p className="mar-bot-10">{compData?.description}</p>
                  </div>
                </div>

                {/* <div id="price" className="list-details-section mar-top-80">
                  <h4>Pricing</h4>
                  <div className="room-type-wrapper">
                    <div className="room-type-item">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="feature-left float-left">
                            <h3>Basic Pass</h3>
                            <p>
                              Max : <span> 1 Persons</span>
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="feature-right float-right">
                            <span className="price-amt">$40</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="room-type-item">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="feature-left float-left">
                            <h3>Standard Pass</h3>
                            <p>
                              Max : <span> 2 Persons</span>
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="feature-right float-right">
                            <span className="price-amt">$80</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="room-type-item">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="feature-left float-left">
                            <h3>Golden Pass</h3>
                            <p>
                              Max : <span> 4 Persons</span>
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="feature-right float-right">
                            <span className="price-amt">$150</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div id="reviews" className="list-details-section mar-top-80">
                  <Review reviews={compData.reviews ? compData.reviews : []} />
                </div>
                <div id="add_review" className="list-details-section mar-top-80">
                  <ReviewsForm user_id={compData.user_id} />
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="listing-sidebar">
                <div class="sidebar-widget info">
                  <QueryForm user_id={compData.user_id} />
                </div>
                <div class="sidebar-widget">
                  <div id="map"></div>
                  <div class="address">
                    <span class="ion-ios-location"></span>
                    <p> {compData?.address}</p>
                  </div>
                  <div class="address">
                    <span class="ion-ios-telephone"></span>
                    <p> {compData?.phone}</p>
                  </div>
                  <div class="address">
                    <span class="ion-android-globe"></span>
                    <p>{compData?.website}</p>
                  </div>
                </div>
                <div class="sidebar-widget">
                  <div class="business-time">
                    <div class="business-title">
                      <h6>
                        <i class="ion-android-alarm-clock"></i> Business Hours
                      </h6>
                      {/* <span class="float-right">Open Now</span> */}
                    </div>
                    <ul class="business-hours">
                      <li class="business-open">
                        <span class="day">Saturday</span>
                        <div class="atbd_open_close_time">
                          <span class="time">11:00 am</span> -{" "}
                          <span class="time">06:00 pm</span>
                        </div>
                      </li>
                      <li class="business-open trend-closed">
                        <span class="day">Sunday</span>
                        <div class="atbd_open_close_time">
                          <span class="time">Closed</span>
                        </div>
                      </li>
                      <li class="business-open">
                        <span class="day">Monday</span>
                        <div class="atbd_open_close_time">
                          <span class="time">10:00 am</span> -{" "}
                          <span class="time">06:00 pm</span>
                        </div>
                      </li>
                      <li class="business-open">
                        <span class="day">Tuesday</span>
                        <div class="atbd_open_close_time">
                          <span class="time">10:00 am</span> -{" "}
                          <span class="time">06:30 pm</span>
                        </div>
                      </li>
                      <li class="business-open">
                        <span class="day">Wednesday</span>
                        <div class="atbd_open_close_time">
                          <span class="time">09:00 am</span> -{" "}
                          <span class="time">05:00 pm</span>
                        </div>
                      </li>
                      <li class="business-open">
                        <span class="day">Thursday</span>
                        <div class="atbd_open_close_time">
                          <span class="time">10:00 am</span> -{" "}
                          <span class="time">07:00 pm</span>
                        </div>
                      </li>
                      <li class="business-open">
                        <span class="day">Friday</span>
                        <div class="atbd_open_close_time">
                          <span class="time">11:00 am</span> -{" "}
                          <span class="time">06:00 pm</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;
