import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { actions } from "../../../actions/actions";

const SuppliersCity = () => {
  const dispatch = useDispatch()
    const navigate = useNavigate();
    const stateData = useSelector((state) => state);
    const [cityList, setCityList] = useState("");
    const fetchListing=(id)=>{
        dispatch(actions.getCompProfile({ name: 'city', id: id ? id : 'x', page: 0 }))
        navigate('/listing');
}
  return (
    <div className="hero-catagory mar-top-30 section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2 text-center">
            <h2 className="section-title v1">Find Suppliers from Top Cities</h2>
          </div>
          <div className="col-md-12">
            {/* <div className="swiper-container popular-place-wrap"> */}
              <div className="hero-catagory-wrapper v1">
                {[
                  { id: 706, city: "delhi" },
                  { id: 1126, city: "Gurgaon" },
                  // { id: 5022, city: "Noida" },
                  { id: 3659, city: "Chennai" },
                  // { id: 1168, city: "Panipat" },
                  { id: 5583, city: "Kolkata" },
                  { id: 2707, city: "Mumbai" },
                ].map((item) => {
                  return (
                      <Link
                        to="#"
                        onClick={() => fetchListing(item.id)}
                        className="hero-category-content v1"
                      >
                        <i className="icofont-hotel"></i>
                        <p className="name">{item.city}</p>
                        <p className="d-name">{item.city}</p>
                      </Link>
                  );
                })}
                 <Link
                        to={`/city-business-directory`}
                        className="hero-category-content v1"
                      >
                        <i className="icofont-hotel"></i>
                        <p className="name">More</p>
                        <p className="d-name">More</p>
                      </Link>
              </div>
            {/* </div> */}
            {/* <div className="slider-btn v1 popular-next">
              <i className="ion-arrow-right-c"></i>
            </div>
            <div className="slider-btn v1 popular-prev">
              <i className="ion-arrow-left-c"></i>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuppliersCity;
