import React from 'react';

const Heading = props => {
    return (
        <nav class="bg-transparent breadcrumb breadcrumb-2 px-0 mb-5" aria-label="breadcrumb">
      <h2 class="font-weight-normal mb-4 mb-md-0">{props.heading}</h2>
      {/* <ul class="list-unstyled d-flex p-0 m-0">
        <li class="breadcrumb-item"><a href="index-2.html">Home</a></li>
        <li class="breadcrumb-item">Dashboard</li>
      </ul> */}
    </nav>
    );
};

export default Heading;