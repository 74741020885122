import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../actions/actions';

const UpdateList = props => {
    const dispatch = useDispatch();
    var compData = JSON.parse(localStorage.getItem('compDetails'));
    var social = JSON.stringify(compData.social);
    const stateData = useSelector((state) => state);
    const [userId, setUserId] = useState("");
    const [title, setTitle] = useState(compData.title);
    const [category, setCategory] = useState(compData.category.id);
    const [desc, setDesc] = useState(compData.description);
    const [tag, setTag] = useState("");
    const [region, setRegion] = useState(compData.area_id);
    const [address, setAddress] = useState(compData.address);
    const [website, setWebsite] = useState(compData.website);
    const [phone, setPhone] = useState(compData.phone);
    const [email, setEmail] = useState(compData.email);
    const [img, setImg] = useState("");
    const [video, setVideo] = useState(compData.video);
    const [linkedin, setLinkedin] = useState(compData[2]);
    const [facebook, setFacebook] = useState(compData[0]);
    const [twitter, setTwitter] = useState(compData[1]);
    const [youtube, setYoutube] = useState(compData[3]);
    const [monEnd, setMonEnd] = useState("");
    const [monStart, setMonStart] = useState("");
    const [tueStart, setTueStart] = useState("");
    const [tueEnd, setTueEnd] = useState("");
    const [wedEnd, setWedStart] = useState("");
    const [wedStart, setWedEnd] = useState("");
    const [thursStart, setThursStart] = useState("");
    const [thursEnd, setThursEnd] = useState("");
    const [friEnd, setFriEnd] = useState("");
    const [friStart, setFriStart] = useState("");
    const [satStart, setSatStart] = useState("");
    const [satEnd, setSatEnd] = useState("");
    const [sunEnd, setSunEnd] = useState("");
    const [sunStart, setSunStart] = useState("");
    // const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [cityList, setCityList] = useState("");
    const [stateList, setStateList] = useState("");
    const [countryList, setCountryList] = useState(stateData.country);

    var details = {};
    var social = [];
    const getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };
    const handleFileInputChange = e => {
        var file = e.target.files[0];
        getBase64(file)
            .then(result => {
                setImg(result);
            })
            .catch(err => {
                console.log(err);
            });

    };
    const pushProfile = () => {
      
        social = [
            facebook,
            twitter,
            linkedin,
            youtube
        ]
        details['id'] = compData.id;
        details['user_id'] = 2;
        details['title'] = title;
        details['category'] = category;
        details['description'] = desc;
        details['area_id'] = region;
        details['address'] = address;
        details['website'] = website;
        details['phone'] = phone;
        details['email'] = email;
        details['image'] = img;
        details['video'] = video;
        details['social'] = JSON.stringify(social);
        // details['timings'] = JSON.stringify(timing);
        details['country_id'] = country;
        details['state_id'] = state;
        dispatch(actions.postCompProfile(details));
        // alert(JSON.stringify(details))
    }

    const getState = (id) => {
        let index = countryList.findIndex(x => x.id == id);
        setStateList(countryList[index].state);
    }

    const getCity = (id) => {
        let index = stateList.findIndex(x => x.id == id);
        setCityList(stateList[index].city);
    }
    return (
        <section class="bg-light py-5 height100vh">
            <div class="container">
                <nav class="bg-transparent breadcrumb breadcrumb-2 px-0 mb-5" aria-label="breadcrumb">
                    <h2 class="font-weight-normal mb-4 mb-md-0">Edit Company Details</h2>
                    {/* <ul class="list-unstyled d-flex p-0 m-0">
				<li class="breadcrumb-item"><a href="index-2.html">Home</a></li>
	      <li class="breadcrumb-item"><a href="dashboard-list-admin.html">Dashboard</a></li>
	      <li class="breadcrumb-item active" aria-current="page">Submit Listings</li>
	    </ul> */}
                </nav>

                <form>
                    <div class="card">
                        <div class="card-body px-6 pt-6 pb-1">
                            <h3 class="h4 mb-4">About</h3>
                            <p class="mb-5">We are not responsible for any damages caused by the use of this website, or by posting business listings here. Please use our site at your own discretion and exercise good judgement as well as common sense when advertising business here.</p>
                            <form>
                                <div class="row">
                                    <div class="form-group col-md-6 mb-6">
                                        <label for="listingTitle">Listing Title</label>
                                        <input type="text" value={title} class="form-control" placeholder="Listing Title" onChange={e => setTitle(e.target.value)} />
                                    </div>

                                    <div class="form-group col-md-6 mb-6">
                                        <label for="exampleFormControlSelect1">Category</label>
                                        <div class="select-default bg-white">
                                            {/* <input type="text" class="form-control" id="addTags" placeholder="Add Tags" onChange={e => setCategory(e.target.value)} /> */}
                                            <select class="select-location form-control" onChange={e => setCategory(e.target.value)}>
                                                <option >Select Category</option>
                                                {(stateData.categories ? stateData.categories : []).map((category) => {
                                                    return <option selected={(category.id == compData.category.id) ? true : false} value={category.id}>{category.name}</option>
                                                })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-12 mb-6">
                                        <label for="discribeTheListing">Discribe the listing</label>
                                        <textarea class="form-control" rows="6" placeholder="Discribe the listing" onChange={e => setDesc(e.target.value)}>{desc}</textarea>
                                    </div>

                                    <div class="form-group col-md-12 mb-6">
                                        <label for="addTags">Tags</label>
                                        <input type="text" class="form-control" id="addTags" placeholder="Add Tags" onChange={e => setTag(e.target.value)} />
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>/
                    <div class="card">
                        <div class="card-body p-6">
                            <h3 class="h4 mb-4">Contact</h3>
                            <p class="mb-5">We are not responsible for any damages caused by the use of this website, or by posting business listings here.
                                Please use our site at your own discretion and exercise good judgement as well as common sense when advertising
                                business here.</p>

                            <div class="row">
                                <div class="col-md-6">

                                    <div class="form-group mb-6">
                                        <label for="exampleFormControlSelect1">Listing Country</label>
                                        <div class="select-default bg-white">
                                            <select class="select-location form-control" onChange={e => {
                                                setCountry(e.target.value)
                                                getState(e.target.value)
                                            }}>
                                                <option value="">Select Country</option>
                                                {(countryList ? countryList : []).map((country) => {
                                                    return <option value={country.id}>{country.name}</option>
                                                })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group mb-6">
                                        <label for="exampleFormControlSelect1">Listing State</label>
                                        <div class="select-default bg-white">
                                            <select class="select-location form-control" onChange={e => {
                                                setState(e.target.value)
                                                getCity(e.target.value)
                                            }}>
                                                <option value="">Select State</option>
                                                {(stateList ? stateList : []).map((state) => {
                                                    return <option value={state.id}>{state.name}</option>
                                                })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group mb-6">
                                        <label for="exampleFormControlSelect1">Listing City</label>
                                        <div class="select-default bg-white">
                                            <select class="select-location form-control" onChange={e => setRegion(e.target.value)}>
                                                <option value="">Select City</option>
                                                {(cityList ? cityList : []).map((city) => {
                                                    return <option selected={(city.id == region) ? true : false} value={city.id}>{city.name}</option>
                                                })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div class="form-group mb-6">
                                        <label for="listingPhone">Listing Phone</label>
                                        <input type="phone" value={phone} class="form-control" placeholder="546 - 545 - 2546" onChange={e => setPhone(e.target.value)} />
                                    </div>

                                    <div class="form-group mb-6">
                                        <label for="listingEmail">Listing Email</label>
                                        <input type="email" value={email} class="form-control" placeholder="hello@example.com" onChange={e => setEmail(e.target.value)} />
                                    </div>

                                    <div class="form-group mb-6">
                                        <label for="listingWebsite">Listing Website</label>
                                        <input type="text" value={website} class="form-control" id="listingWebsite" placeholder="http://" onChange={e => setWebsite(e.target.value)} />
                                    </div>

                                </div>

                                <div class="col-md-6 map-place-search-wrapper">
                                    <div class="form-group mb-0 ">
                                        <label for="listingAddress">Listing Address</label>
                                        <input type="text" class="form-control" id="listingAddress" value={address} placeholder="Type Location" onChange={e => setAddress(e.target.value)} />
                                    </div>

                                    <div class="map-place-search">
                                        <div id="map-add-edit"></div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    /
                    <div class="card">
                        <div class="card-body p-6">
                            <h3 class="h4 mb-4">Gallery</h3>
                            <p class="mb-5">We are not responsible for any damages caused by the use of this website, or by posting business listings here.
                                Please use our site at your own discretion and exercise good judgement as well as common sense when advertising
                                business here.</p>

                            <div class="form-group">
                                <input type="file" name="file" class="form-control" onChange={e => handleFileInputChange(e)} />
                            </div>

                            <div class="form-group">
                                <label for="videoUrl">Video URL</label>
                                <input type="text" value={video} class="form-control" id="videoUrl" placeholder="http://" onChange={e => setVideo(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    /
                    <div class="card">
                        <div class="card-body p-6">
                            <h3 class="h4 mb-4">Social</h3>
                            <p class="mb-5">We are not responsible for any damages caused by the use of this website, or by posting business listings here.
                                Please use our site at your own discretion and exercise good judgement as well as common sense when advertising
                                business here.</p>

                            <div class="row">
                                <div class="form-group col-md-6 mb-6">
                                    <label for="linkedUrl">Linked in URL</label>
                                    <input type="text" value={linkedin} class="form-control" placeholder="http://linkedin.com/listty" onChange={e => setLinkedin(e.target.value)} />
                                </div>

                                <div class="form-group col-md-6 mb-6">
                                    <label for="facebookUrl">Facebook URL</label>
                                    <input type="text" value={facebook} class="form-control" placeholder="http://facebook.com/listty" onChange={e => setFacebook(e.target.value)} />
                                </div>

                                <div class="form-group col-md-6 mb-6">
                                    <label for="twitterUrl">Twitter URL</label>
                                    <input type="text" value={twitter} class="form-control" placeholder="http://twitter.com/listty" onChange={e => setTwitter(e.target.value)} />
                                </div>

                                <div class="form-group col-md-6 mb-6">
                                    <label for="youtubeUrl">You Tube URL</label>
                                    <input type="text" value={youtube} class="form-control" placeholder="http://youtube.com/listty" onChange={e => setYoutube(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-7 col-lg-6 col-xl-5">
                            <div class="mb-6">
                                <button type="button" class="btn btn-lg btn-primary btn-block" onClick={() => pushProfile()}>submit</button>
                            </div>
                        </div>
                    </div>

                </form>

            </div>
        </section>
    );
};

export default UpdateList;