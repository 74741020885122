import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';

function Spinner() {
    const appData = useSelector(state => state);
    const [loading, setLoading] = useState(false);
    useEffect(() => {

        setLoading(appData.loadingStatus);

    }, [JSON.stringify(appData)])

    return (
       
        <div>
             { loading ? 
            <>
            <div className="modal fade show" style={{ display: 'block' }} tabindex="-1" role="dialog">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content modal-content-trans-bg">
                            <div className="modal-body">
                                <div className="loader"></div>
                                <div className="processing">Please Wait...</div>
                            </div>
                        </div>
                    </div>
                </div><div className="modal-backdrop fade show"></div>
                </>
             :""} 
        </div>
        
    );
}

export default Spinner;
