import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { actions } from '../../actions/actions';

const ListProd = props => {
    const stateData = useSelector((state) => state);
    const [details, setDetails] = useState({});
    const [showModal, setshowModal] = useState(0);
    const [leadData, setLeadData] = useState(stateData.search ? stateData.search : []);
    const closeModal = () => {
        setshowModal(0);
    }
    useEffect(() => {
        setLeadData(stateData.search);
    }, [JSON.stringify(stateData.search)])

    return (
        <div className="col-lg-8 order-lg-1">
            {leadData.data?.map((list)=>{

            
           return <div className="card card-list card-listing" data-lat="-33.922125" data-lag="151.159277" data-id="1">
                <div className="row">
                    <div className="col-sm-5 col-xl-4">
                        <div className="card-list-img">
                            <img className="listing-img" src="/assets/img/listing/listing-4.jpg" alt="things-4" />
                            {/* <span className="badge badge-primary">Verified</span> */}
                        </div>

                    </div>
                    <div className="col-sm-7 col-xl-8">
                        <div className="card-body p-0">
                            <ul className="list-inline list-inline-rating">
                                <li className="list-inline-item">
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                </li>
                                <li className="list-inline-item">
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                </li>
                                <li className="list-inline-item">
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                </li>
                                <li className="list-inline-item">
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                </li>
                                <li className="list-inline-item">
                                    <i className="fa fa-star-o" aria-hidden="true"></i>
                                </li>
                            </ul>

                            <div className="d-flex justify-content-between align-items-center mb-1">
                                <h3 className="card-title listing-title mb-0">
                                <Link to="#"> {list.title} </Link>
                                </h3>
                                <button className="btn-like px-2" data-toggle="tooltip" data-placement="top" title="Favourite this listing">
                                    <i className="fa fa-heart-o text-primary" aria-hidden="true"></i>
                                    <span>8 k</span>
                                </button>
                            </div>

                        </div>
                        <span className="d-block mb-4 listing-address">{list.category_id}</span>
                        {/* <p className="mb-4">{list.description}</p> */}
                        {/* <div>
                            <Link to="#" onClick={()=>compDetails(list)}>More &gt;</Link>
                        </div> */}
                    </div>
                </div>
            </div>
            })}
            {/* {!profileData.length?
            <div class="alert alert-info" role="alert">
            <h4>No data found</h4>
          </div>
            :""} */}
            
            
            
        </div>

    );
};

export default ListProd;