import axios from "axios";
import { Constants } from "../contants";
import { AlertService } from "./alertService";
import { actions } from "../actions/actions";
import store from "../store/store";

axios.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem("token");
    config.headers.accept = "application/json";
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function getCategory() {
  return new Promise((resolve, reject) => {
    axios
      .get(Constants.API_URL + "categories")
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
      });
  });
}
function getSubCategory() {
  return new Promise((resolve, reject) => {
    axios
      .get(Constants.API_URL + "subcategories")
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
      });
  });
}
function getMicroCategory() {
  return new Promise((resolve, reject) => {
    axios
      .get(Constants.API_URL + "microcategories")
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
      });
  });
}
function getTestimonials() {
  return new Promise((resolve, reject) => {
    axios
      .get(Constants.API_URL + "testimonials")
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
      });
  });
}

function getPackageRequest() {
  return new Promise((resolve, reject) => {
    axios
      .get(Constants.API_URL + "reqpackage")
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
      });
  });
}

function getBanner() {
  return new Promise((resolve, reject) => {
    axios
      .get(Constants.API_URL + "banners")
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
      });
  });
}
function getBrandBaner() {
  return new Promise((resolve, reject) => {
    axios
      .get(Constants.API_URL + "brandbanners")
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
      });
  });
}

function getVideos() {
  return new Promise((resolve, reject) => {
    axios
      .get(Constants.API_URL + "videos")
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
      });
  });
}
function getMainCategory() {
  return new Promise((resolve, reject) => {
    axios
      .get(Constants.API_URL + "maincat")
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
      });
  });
}
function getSearchData(param) {
  var query = param.params;
  showSpinner(true);
  return new Promise((resolve, reject) => {
    axios
      .get(
        Constants.API_URL +
          "search/" +
          query.type +
          "/" +
          query.term +
          "?page=" +
          query.page
      )
      .then((res) => {
        resolve(res.data);
        showSpinner(false);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
      });
  });
}
function getCountry() {
  return new Promise((resolve, reject) => {
    axios
      .get("json/country.json")
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
      });
  });
}

function getUserCategory() {
  return new Promise((resolve, reject) => {
    axios
      .get(Constants.API_URL + "usercategory")
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
      });
  });
}
function getLeads() {
  return new Promise((resolve, reject) => {
    axios
      .get(Constants.API_URL + "leads")
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
      });
  });
}
function getCompProfile(action) {
  showSpinner(true);
  let param = action.params ? action.params.page : 0;
  let id = action.params ? action.params.id : 0;
  let name = action.params ? action.params.name : null;
  return new Promise((resolve, reject) => {
    axios
      .get(
        Constants.API_URL +
          "companyprofile/" +
          name +
          "/" +
          id +
          "?page=" +
          param
      )
      .then((res) => {
        resolve(res.data);
        showSpinner(false);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
      });
  });
}
function getCompProfileHome() {
  return new Promise((resolve, reject) => {
    axios
      .get(Constants.API_URL + "companyprofilehome")
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        // AlertService.errorAlert(error.response.data.message);
      });
  });
}
function getProduct() {
  return new Promise((resolve, reject) => {
    axios
      .get(Constants.API_URL + "categories")
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
      });
  });
}
function getCity() {
  return new Promise((resolve, reject) => {
    axios
      .get(Constants.API_URL + "city")
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
      });
  });
}
function getArea() {
  return new Promise((resolve, reject) => {
    axios
      .get(Constants.API_URL + "area")
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
      });
  });
}
function getState() {
  return new Promise((resolve, reject) => {
    axios
      .get(Constants.API_URL + "state")
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
      });
  });
}
function doLogin(body) {
  return new Promise((resolve, reject) => {
    axios
      .post(Constants.API_URL + "login", body.details)
      .then((res) => {
        AlertService.sucessAlert("Login Success...");
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert("Please check your login details");
        reject(error);
        // console.log(error.response.data.message);
      });
  });
}
function doRegister(body) {
  return new Promise((resolve, reject) => {
    axios
      .post(Constants.API_URL + "register", body.details)
      .then((res) => {
        AlertService.sucessAlert("Registered successfully");
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
        reject(error);
        // console.log(error.response.data.message);
      });
  });
}
function postCategory(body) {
  return new Promise((resolve, reject) => {
    axios
      .post(Constants.API_URL + "categories", body.categories)
      .then((res) => {
        store.dispatch(actions.getCategory());
        store.dispatch(actions.getMainCategory());
        AlertService.sucessAlert("Category added successfuly");
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
        reject(error);
        // console.log(error.response.data.message);
      });
  });
}
function addSubCategory(body) {
  return new Promise((resolve, reject) => {
    axios
      .post(Constants.API_URL + "subcategories", body)
      .then((res) => {
        AlertService.sucessAlert("Sub category added successfuly");
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
        reject(error);
        // console.log(error.response.data.message);
      });
  });
}
function addMicroCategory(body) {
  return new Promise((resolve, reject) => {
    axios
      .post(Constants.API_URL + "microcategories", body)
      .then((res) => {
        AlertService.sucessAlert("Sub category added successfuly");
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
        reject(error);
        // console.log(error.response.data.message);
      });
  });
}
function postPackageRequest(body) {
  return new Promise((resolve, reject) => {
    axios
      .post(Constants.API_URL + "reqpackage", body.packageRequest)
      .then((res) => {
        store.dispatch(actions.getPackageRequest());
        AlertService.sucessAlert("Request sent successfuly");
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
        reject(error);
        // console.log(error.response.data.message);
      });
  });
}
function addCompanyDetails(body) {
  return new Promise((resolve, reject) => {
    axios
      .post(Constants.API_URL + "companyprofileabout/add", body)
      .then((res) => {
        store.dispatch(actions.getPackageRequest());
        AlertService.sucessAlert("Request sent successfuly");
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
        reject(error);
        // console.log(error.response.data.message);
      });
  });
}
function postLeads(body) {
  return new Promise((resolve, reject) => {
    axios
      .post(Constants.API_URL + "leads", body.leads)
      .then((res) => {
        store.dispatch(actions.getLeads());
        AlertService.sucessAlert("Requirement added successfuly");
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
        reject(error);
        // console.log(error.response.data.message);
      });
  });
}

function postReviews(body) {
  return new Promise((resolve, reject) => {
    axios
      .post(Constants.API_URL + "reviews", body.review)
      .then((res) => {
        AlertService.sucessAlert("Review posted successfuly");
        store.dispatch(actions.getCompProfile());
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
        reject(error);
        // console.log(error.response.data.message);
      });
  });
}

function postCity(body) {
  return new Promise((resolve, reject) => {
    axios
      .post(Constants.API_URL + "city", body.city)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
        reject(error);
        // console.log(error.response.data.message);
      });
  });
}

function postProduct(body) {
  return new Promise((resolve, reject) => {
    axios
      .post(Constants.API_URL + "product", body.product)
      .then((res) => {
        resolve(res.data);
        AlertService.sucessAlert("Product added successfuly");
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
        reject(error);
        // console.log(error.response.data.message);
      });
  });
}

function postCompProfile(body) {
  showSpinner(true);
  return new Promise((resolve, reject) => {
    axios
      .post(Constants.API_URL + "companyprofile", body.compProfile)
      .then((res) => {
        AlertService.sucessAlert("List added successfully");
        resolve(res.data);
        showSpinner(false);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
        reject(error);
        // console.log(error.response.data.message);
      });
  });
}

function postState(body) {
  return new Promise((resolve, reject) => {
    axios
      .post(Constants.API_URL + "state", body.state)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
        reject(error);
        // console.log(error.response.data.message);
      });
  });
}
function postUserCategory(body) {
  return new Promise((resolve, reject) => {
    axios
      .post(Constants.API_URL + "usercategory", body.category)
      .then((res) => {
        AlertService.sucessAlert("Category added successfuly");
        resolve(res.data);
      })
      .catch((error) => {
        AlertService.errorAlert(error.response.data.message);
        reject(error);
        // console.log(error.response.data.message);
      });
  });
}
function getUserDetails() {
  return JSON.parse(localStorage.getItem("user"));
}
function showSpinner(status) {
  store.dispatch(actions.setLoadingStatus(status));
}

export const appService = {
  getUserDetails,
  getCategory,
  doLogin,
  postCategory,
  postCity,
  getCity,
  postState,
  getState,
  postProduct,
  getProduct,
  getArea,
  doRegister,
  postCompProfile,
  getCompProfile,
  getLeads,
  postLeads,
  postReviews,
  postUserCategory,
  getUserCategory,
  getCountry,
  getSearchData,
  getMainCategory,
  getPackageRequest,
  postPackageRequest,
  getCompProfileHome,
  getTestimonials,
  getSubCategory,
  addSubCategory,
  getMicroCategory,
  addMicroCategory,
  getVideos,
  getBrandBaner,
  getBanner,
  addCompanyDetails
};
