import React from 'react';
import PropTypes from 'prop-types';
import { AlertService } from '../../services/alertService';
import { useNavigate } from 'react-router-dom';

const LeadDetailsModal = props => {
    let user = JSON.parse(localStorage.getItem('user'));
        let userRole = user ? user.role : "";
    const navigate = useNavigate()
    const contactSuplier = () => {
        AlertService.infoAlert("Please join us to see the full lead details");
        setTimeout(() => {
            navigate('/login');
        }, 1000);
    }
    const hideModal=()=>{
        props.hideModal();
    }
    return (
        <div>
            <div class="modal fade show" style={{ display: 'block' }} tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Lead Details</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>hideModal()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <table className='table'>
                                <tr>
                                    <td>Name : </td>
                                    <td>{props.details.name}</td>
                                </tr>
                                {userRole?
                                <>
                                <tr>
                                    <td>Phone : </td>
                                    <td>{props.details.phone}</td>
                                </tr>
                                <tr>
                                    <td>Email : </td>
                                    <td>{props.details.email}</td>
                                </tr>
                                </>
                                :""}
                                <tr>
                                    <td>Requirement : </td>
                                    <td>{props.details.requirement}</td>
                                </tr>
                                {props.details.city!=null?
                                <>
                                <tr>
                                    <td>Estimated Order Quantity : </td>
                                    <td>{props.details.quantity}</td>
                                </tr>
                                <tr>
                                    <td>Estimated Order Value : </td>
                                    <td>{props.details.unit}</td>
                                </tr>
                                <tr>
                                    <td>City : </td>
                                    <td>{props.details.city.name}</td>
                                </tr>
                                </>
                                :""}
                                <tr>
                                    <td>Posted Date : </td>
                                    <td>{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(props.details.created_at))}</td>
                                </tr>

                                </table>
                        </div>
                        <div class="modal-footer">
                        {userRole?"":
                            <button type="button" class="btn btn-primary" onClick={() => contactSuplier()}>Contact Buyer</button>
                                }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default LeadDetailsModal;