import logo from "./logo.svg";
// import './App.css';
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "./components/layout";
import Home from "./components/home";
import Listing from "./components/listing";
import Login from "./components/login";
import Category from "./components/category";
import City from "./components/city";
import State from "./components/state";
import Product from "./components/product";
import Register from "./components/register";
import Addlist from "./components/add_list";
import { Constants } from "./contants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CategoryList from "./components/category/list";
import StateList from "./components/state/list";
import CityList from "./components/city/list";
import PostRequirement from "./components/requirement";
import CompanyDetails from "./components/compDetails";
import UserLeadList from "./components/userLeads/list";
import UserCategory from "./components/users/components/categoty";
import UserCategoryList from "./components/users/components/categoty/list";
import PricePlan from "./components/pricing";
import LeadSearch from "./components/leadSearch";
import ProductListing from "./components/product_listing";
import PackageRequestList from "./components/admin/packageRequests";
import Payments from "./components/payments";
import AdminList from "./components/admin/listing/list";
import UpdateList from "./components/admin/listing/update";
import AllLeads from "./components/allleads";
import ScrollToTop from "./scrolltotop";
import AllCategories from "./components/all_categories";
import CityBusinessDirectory from "./components/city_business_directory";
import Spinner from "./components/loading";
import Chat from "./components/chat/chat";
import SubCategory from "./components/subcategory";
import SubCategoryList from "./components/subcategory/list";
import MicroCategory from "./components/microcategory";
import MicroCategoryList from "./components/microcategory/list";
import AboutUs from "./components/about";
import Review from "./components/compDetails/review";
import { PublicRoutes } from "./routes/PublicRoute";
import { ProtectedRoutes } from "./routes/ProtectedRoute";
import CompanyAbout from "./components/users/components/about";

function App() {
  return (
    <div className="App">
      {/* <BrowserRouter basename={Constants.BASE_ROUTER}> */}
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route path="/" element={<Home />} />
            <Route path="listing" element={<Listing />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="review" element={<Review />} />
            <Route path="leads" element={<UserLeadList />} />
            <Route path="pricing" element={<PricePlan />} />
            <Route path="search/lead" element={<LeadSearch />} />
            <Route path="search/product" element={<ProductListing />} />
            <Route path="payments" element={<Payments />} />
            <Route path="allLeads" element={<AllLeads />} />
            <Route path="allcategories" element={<AllCategories />} />
            <Route
              path="city-business-directory"
              element={<CityBusinessDirectory />}
            />

            <Route path="listing/details" element={<CompanyDetails />} />
            <Route path="*" element={<Home />} />
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route
              path="admin/packagereq/list"
              element={<PackageRequestList />}
            />
            <Route path="listing/add" element={<Addlist />} />
            <Route path="company/details/add" element={<CompanyAbout />} />
            <Route path="admin/company/list" element={<AdminList />} />
            <Route path="/admin/company/edit" element={<UpdateList />} />
            <Route path="user/category/add" element={<UserCategory />} />
            <Route path="user/category/list" element={<UserCategoryList />} />
            <Route path="post/requirement" element={<PostRequirement />} />
            <Route path="category/add" element={<Category />} />
            <Route path="category/list" element={<CategoryList />} />
            <Route path="subcategory/add" element={<SubCategory />} />
            <Route path="subcategory/list" element={<SubCategoryList />} />
            <Route path="microcategory/add" element={<MicroCategory />} />
            <Route path="microcategory/list" element={<MicroCategoryList />} />
            <Route path="city/add" element={<City />} />
            <Route path="city/list" element={<CityList />} />
            <Route path="state/add" element={<State />} />
            <Route path="state/list" element={<StateList />} />
            <Route path="product/add" element={<Product />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
      <Spinner />
      <Chat />
    </div>
  );
}

export default App;
