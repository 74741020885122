import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { actions } from "../../actions/actions";

const SidebarProd = () => {
  const stateData = useSelector((state) => state);
  const dispatch = useDispatch();
  const requestList = (selectedPage, name, id) => {
    dispatch(actions.getCompProfile({ name: name, id: id, page: selectedPage }))
  }
  return (
    <div className="col-lg-4 pr-xl-6">
      <div className="mb-6">
        <label className="mb-4 h5 font-weight-normal text-dark" htmlFor="enter-keyowrd">What are you looking for?</label>
        <input type="text" className="form-control" id="enter-keyowrd" placeholder="Enter Keyword"  onBlur={(e) => requestList(0, 'keyword', e.target.value)}/>
      </div>
{/* 
      <div className="mb-6">
        <label className="mb-4 h5 font-weight-normal text-dark" htmlFor="enter-keyowrd">Find By Location</label>
        <input type="text" className="form-control" id="enter-location" placeholder="Enter Location" />
      </div> */}

      <div className="mb-6">
        <h5 className="mb-4 font-weight-normal">Filter by Area</h5>
        <ul className="list-unstyled list-gray">
          {(stateData.area ? stateData.area : []).map((areas) => {
            return <li className="mb-2" onClick={() => requestList(0, 'area', areas.id)}><Link to="#">{areas.name}</Link></li>
          })
          }
        </ul>
      </div>

      <div className="mb-6">
        <h5 className="mb-4 font-weight-normal">Related Categories</h5>
        <ul className="list-unstyled list-gray">
          {(stateData.categories ? stateData.categories : []).map((category) => {
            return <li class="mb-2" onClick={() => requestList(0, 'cat', category.id)}><Link to="#">{category.name}</Link></li>
          })
          }
        </ul>
      </div>

    </div>
  )
}

export default SidebarProd;