import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IndianPlan from './indianPlan';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import GlobalPlan from './globalPlan';
import PackageInquiryFormModal from './modal';

const PricePlan = props => {
    const [showModal, setshowModal] = useState(false);
    const [packages, setPackage] = useState(false);
    const [amt, setAmt] = useState(0);
    const openModal = () => {
        setshowModal(true);
    }
    const getPackage=(amount,price)=>{
        setPackage(amount);
        setAmt(price);
    }
    const closeModal = () => {
        setshowModal(false);
    }
    return (
        <><div
            className="breadcrumb-section"
            style={{ backgroundImage: "url(images/breadcrumb/breadcrumb-2.jpg)" }}
        >
            <div className="overlay op-5"></div>
            <div className="container">
                <div className="row align-items-center  pad-top-80">
                    <div className="col-md-6 col-12">
                        <div className="breadcrumb-menu">
                            <h2 className="page-title">Pricing Table</h2>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="breadcrumb-menu text-right sm-left">
                            <ul>
                                <li className="active">
                                    <a href="#">Home</a>
                                </li>
                                <li>
                                    <a href="#">Pricing Table</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div><div className="list-details-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3 text-center">
                            <ul
                                className="nav nav-tabs list-details-tab pricing-list"
                                id="lionTab"
                                role="tablist"
                            >
                                <li className="nav-item active">
                                    <a data-toggle="tab" href="#monthly">
                                        Monthly
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a data-toggle="tab" href="#yearly">
                                        Yearly
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-12">
                            <div
                                className="tab-content mar-top-30 mar-bot-20"
                                id="lionTabContent"
                            >
                                <div className="tab-pane fade active show" id="monthly">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className="pricing-table">
                                                <h3 className="title">Standard</h3>
                                                <div className="price-value">
                                                    <div className="price-num">
                                                        <div className="price-num-item">
                                                            <span className="mouth-cont">
                                                                <span className="curen">$</span>20
                                                            </span>
                                                            <p>Per month</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul className="pricing-content">
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>One Listing
                                                    </li>
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>90 Days
                                                        Availability
                                                    </li>
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>Non-Featured
                                                    </li>
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>Limited Support
                                                    </li>
                                                </ul>
                                                <a href="#" className="btn v3">
                                                    Get Started
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className="pricing-table blue featured">
                                                <div className="listing-badge">
                                                    <span className="featured">Featured</span>
                                                </div>
                                                <h3 className="title">Business</h3>
                                                <div className="price-value">
                                                    <div className="price-num">
                                                        <div className="price-num-item">
                                                            <span className="mouth-cont">
                                                                <span className="curen">$</span>49
                                                            </span>
                                                            <p>Per month</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul className="pricing-content">
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>One Time Fee
                                                    </li>
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>One Listing
                                                    </li>
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>Lifetime
                                                        Availability
                                                    </li>
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>Featured In Search
                                                        Results
                                                    </li>
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>24/7 Support
                                                    </li>
                                                </ul>
                                                <a href="#" className="btn v3">
                                                    Get Started
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className="pricing-table green">
                                                <h3 className="title">Premium</h3>
                                                <div className="price-value">
                                                    <div className="price-num">
                                                        <div className="price-num-item">
                                                            <span className="mouth-cont">
                                                                <span className="curen">$</span>49
                                                            </span>
                                                            <p>Per month</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul className="pricing-content">
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>Unlimited Listing
                                                    </li>
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>Lifetime
                                                        Availability
                                                    </li>
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>Featured In Search
                                                        Results
                                                    </li>
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>24/7 Support
                                                    </li>
                                                </ul>
                                                <a href="#" className="btn v3">
                                                    Get Started
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="yearly">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className="pricing-table">
                                                <h3 className="title">Standard</h3>
                                                <div className="price-value">
                                                    <div className="price-num">
                                                        <div className="price-num-item">
                                                            <span className="mouth-cont">
                                                                <span className="curen">$</span>220
                                                            </span>
                                                            <p>Per month</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul className="pricing-content">
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>One Listing
                                                    </li>
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>90 Days
                                                        Availability
                                                    </li>
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>Non-Featured
                                                    </li>
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>Limited Support
                                                    </li>
                                                </ul>
                                                <a href="#" className="btn v3">
                                                    Get Started
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className="pricing-table blue featured">
                                                <div className="listing-badge">
                                                    <span className="featured">Featured</span>
                                                </div>
                                                <h3 className="title">Business</h3>
                                                <div className="price-value">
                                                    <div className="price-num">
                                                        <div className="price-num-item">
                                                            <span className="mouth-cont">
                                                                <span className="curen">$</span>619
                                                            </span>
                                                            <p>Per month</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul className="pricing-content">
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>One Time Fee
                                                    </li>
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>One Listing
                                                    </li>
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>Lifetime
                                                        Availability
                                                    </li>
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>Featured In Search
                                                        Results
                                                    </li>
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>24/7 Support
                                                    </li>
                                                </ul>
                                                <a href="#" className="btn v3">
                                                    Get Started
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className="pricing-table green">
                                                <h3 className="title">Premium</h3>
                                                <div className="price-value">
                                                    <div className="price-num">
                                                        <div className="price-num-item">
                                                            <span className="mouth-cont">
                                                                <span className="curen">$</span>890
                                                            </span>
                                                            <p>Per month</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul className="pricing-content">
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>Unlimited Listing
                                                    </li>
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>Lifetime
                                                        Availability
                                                    </li>
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>Featured In Search
                                                        Results
                                                    </li>
                                                    <li>
                                                        <i className="icofont-tick-boxed"></i>24/7 Support
                                                    </li>
                                                </ul>
                                                <a href="#" className="btn v3">
                                                    Get Started
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
        // <div>
        //     <section class="bg-light py-8">
        //         <div class="container">
        //             <div class="d-flex flex-column justify-content-center align-items-center">
        //                 <nav aria-label="breadcrumb">
        //                     <h2 class="breadcrumb-title text-center mb-4">Pricing Plan</h2>
                           
        //                 </nav>
        //             </div>
        //         </div>
        //     </section>
        //     <div class="element-wrapper">

        //         <section class="py-7 py-md-10">
        //             <div class="container">

        //                 <Tabs>
        //                     <TabList>
        //                         <Tab>Indian Plan</Tab>
        //                         <Tab>Global Plan</Tab>
        //                     </TabList>

        //                     <TabPanel>
        //                         <IndianPlan openModal={openModal} getPackage={getPackage} />
        //                     </TabPanel>
        //                     <TabPanel>
        //                         <GlobalPlan openModal={openModal} getPackage={getPackage} />
        //                     </TabPanel>
        //                 </Tabs>
        //                 <div class="text-center pt-5">
        //                     <p class="mb-0 text-dark"> Don't have an Account? <a href="sign-up.html">Sign up</a></p>
        //                 </div>
        //             </div>
        //         </section>
        //         {showModal ?
        //             <PackageInquiryFormModal package={packages} amt={amt} hideModal={closeModal} />
        //             : ""}

        //     </div>
        // </div>
    );
};


export default PricePlan;