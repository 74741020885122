import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { actions } from '../../actions/actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Register = props => {
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const [firstName,setFirstName]=useState("");
    const [phone,setPhone]=useState("");
    const [email,setEmail]=useState("");
    const [password,setPassword]=useState("");
    const [cpassword,setcPassword]=useState("");
    var details={};
    const register=()=>{
        details['name']=firstName;
        details['email']=email;
        details['phone']=phone;
        details['password']=password;
        details['password_confirmation']=cpassword;
        details['role']='user';
        dispatch(actions.register(details));
        setTimeout(() => {
          navigate('login');
        }, 2000);
    }
    return (
        <section class="py-7 py-md-10 bg-light height100vh">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-9 col-xl-7">
        <div class="bg-white p-5 rounded border">
          <h2 class="font-weight-normal mb-4">Account Registration</h2>
          <p>Please fill out the fields below to create your account.</p>

          <form class="py-7" action="#">

            <h3 class="h4 font-weight-normal mb-4">Contact Information</h3>
            <div class="row">
              <div class="form-group col-md-6">
                <label for="inputtext">First Name*</label>
                <input type="text" class="form-control" id="inputtext" placeholder="" onChange={(e)=>setFirstName(e.target.value)}/>
              </div>
              <div class="form-group col-md-6">
                <label for="inputText">Phone*</label>
                <input type="text" autoComplete='false' class="form-control" id="inputText" placeholder="" onChange={(e)=>setPhone(e.target.value)}/>
              </div>
            </div>
            {/* <div class="form-group mb-8">
              <label for="inputAddress">Email Address*</label>
              <input type="email" class="form-control" id="inputAddress" placeholder=""/>
            </div> */}

            <h3 class="h4 font-weight-normal mb-4 mt-7">Account Information</h3>
            <div class="form-group">
              <label for="inputText">Email*</label>
              <input type="text" class="form-control" id="inputText" placeholder="" onChange={(e)=>setEmail(e.target.value)}/>
            </div>
            <div class="row mb-6">
              <div class="form-group col-md-6 mb-4">
                <label for="inputPassword">Password*</label>
                <input type="password" class="form-control" id="inputPassword" placeholder="" onChange={(e)=>setPassword(e.target.value)}/>
              </div>
              <div class="form-group col-md-6 mb-4">
                <label for="inputPassword">Password (re-type)*</label>
                <input type="password" class="form-control" id="inputPassword" placeholder="" onChange={(e)=>setcPassword(e.target.value)}/>
              </div>
            </div>

            {/* <h3 class="h4 font-weight-normal mb-4 mt-7">Security Control</h3>
            <div class="form-group mb-7">
              <label for="usernames" class="control-label">
                Confirm that you are human*
              </label> <br/>
              <img src="/assets/img/business/recaptcha.jpg" alt="Image captcha" class="img-fluid">
            </div> */}

            <div class="form-group mb-6">
              <div class="checkbox">
                <label>
                  <input type="checkbox"/>
                  I agree to the <a href="terms-of-services.html">Terms of Use</a> &amp; <a href="#">Privacy Policy</a>. Your
                  business listing is fully backed by our 100% money back guarantee.
                </label>
              </div>
            </div>

            <button type="button" class="btn btn-primary" onClick={()=>register()}> Create Account </button>

          </form>

          
        </div>
      </div>
    </div>
  </div>
</section>
    );
};

export default Register;