import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LeadDetailsModal from "../leadDetailsModal";
import Moment from "moment";
import PageTitle from "../common/page-title";

const AboutUs = (props) => {
  return (
    <>
    <PageTitle title="About Us" />
      <div className="col-lg-12">
        <div class="card">
          {/* <div class="card-header bg-white">
            <span class="text-content">New Leads</span>
          </div> */}

          <div class="card-body p-8 ">
            <h2 class="h4 font-weight-normal mb-3">About Us</h2>
            <p>
              connect2buyers is India's leading B2B marketplace for micro,
              small, and medium enterprises. We connect buyers from every corner
              of India and many countries of tde world witd verified sellers to
              expand your business successfully. We have grown tdousands of
              small and large businesses globally witd tde help of our 13+ years
              of remarkable experience. Our company is established on tde
              principles of transparency, receptiveness, affordability, and
              national reach. Witd complete expert assistance, our company
              offers Quality Assurance services for Import and Export
              businesses. We provide you witd a seamless procedure of deal
              booking for import-export businesses. It includes market research,
              supplier selection, quality control, logistics, and documentation.
              It has been our endeavor for 13 consecutive years to create a
              secure and trusted platform by removing tde loopholes in tde B2B
              marketplace. And we have done tdis tdrough our tireless efforts.
              Our parent company is{" "}
              <a href="https://dgmt.in" target="_blank">
                Digital Growtd Marketing Technology
              </a>
              , and tdis platform is a part of it.{" "}
            </p>

            <h2 class="h4 font-weight-normal mb-3">Meet our founder</h2>
            <p>
              Mr. Pradeep Jha: Hey, tdis is Pradeep! I have spent more tdan 13
              years in digital marketing and Import-Export business. Witd tde
              help of tdis unique experience, I am well aware of all tde ups and
              downs in tdis field. In my career, I have helped 1000+ clients to
              take tdeir businesses to tde top level. My team has professionals
              from every field who are capable of fulfilling all your business
              requirements and digital marketing needs. I understand tdat more
              focus should be on manpower and quality assurance products or
              services to grow tde business. I also helped many clients to grow
              tdeir local businesses globally.
            </p>
            <table class="table table-bodered">
              <tr>
                <td>Name</td>
                <td>Pradeep Jha</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>ownerdgmtech@gmail.com</td>
              </tr>
              <tr>
                <td>Facebook ID</td>
                <td>
                  <a
                    href="https://www.facebook.com/digitalgrowtdmarketingtechnology"
                    target="_blank"
                  >
                    digitalgrowtdmarketingtechnology
                  </a>
                </td>
              </tr>
              <tr>
                <td>Twitter ID</td>
                <td>
                  <a href="https://twitter.com/dgmt_in/" target="_blank">
                    dgmt_in
                  </a>
                </td>
              </tr>
              <tr>
                <td>Instagram ID</td>
                <td>
                  <a href="https://www.instagram.com/dgmt_in/" target="_blank">
                    dgmt_in
                  </a>
                </td>
              </tr>
            </table>

            <h2 className="h4 font-weight-normal mb-3">
              What sets us apart from others?
            </h2>
            <p>
              connect2buyers is a platform where security and client
              satisfaction are given first priority. We connect Filter Buyers to
              take your business to new heights of success, which means that
              first, we check the authenticity of the buyer and then get you
              connected. There is absolutely no chance of getting fake buyers
              here. After joining us, our support team is always with you till
              you take your business to new heights of success. We have a team
              of experts Who shows the profitable way for your business. We
              created an organized B2B marketing platform that helps to meet
              your desired needs. We offer conference calls, sampling, secure
              payment gateway integration, trust stamp, language converter, and
              so many things to connect buyers and sellers
            </p>

            <h2 className="h4 font-weight-normal mb-3">Our Mission:</h2>
            <p>
              Our country India is now on the path of becoming self-reliant and
              financially prosperous. For this, everyone must increase their
              businesses on a large scale. Because if the businesses improve
              their economic condition, then the country also becomes
              financially strong. Global trade is a massive source of income.
              That's why we have only one mission to develop local businesses
              globally with our expert guidance and result-oriented services.
              Additionally, give our significant support in making the country
              self-reliant
            </p>
            <h2 className="h4 font-weight-normal mb-3">Core Values: </h2>
            <p>
              <ul>
                <li>Commitment to Excellence</li>
                <li>Result-oriented approach</li>
                <li>Responsible for client satisfaction</li>
                <li>Honesty and passion</li>
                <li>Dynamic innovations with dedicated teamwork</li>
                <li>Support and industry leadership</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
