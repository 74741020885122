import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../actions/actions";
import { Link, useNavigate } from "react-router-dom";

const CategoryWithSubCategoryItems = (props) => {
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const requestList = (selectedPage, name, id) => {
    console.log("a")
    localStorage.setItem("searchby",name);
localStorage.setItem("searchid",id);
    dispatch(actions.getCompProfile({ name: name, id: id, page: selectedPage }))
    navigate('listing');
}
  return (
    <div className="row category-home">
      <div className="col-3">
        <div className="image">
          <img
            src="https://placehold.co/313x400"
            alt="Explore Food Grains"
          />
        </div>
      </div>
      <div className="col-9">
        <div className="categories">
      {props?.data?.map(
          (item, index) =>
          <div className="item">
            <div className="box2">
              <div className="box2-content">
                <h3 className="title">
                  <a href="#">
                   {item.name}
                  </a>
                </h3>
                <ul className="links">
                {item?.category?.map((item1, index) => (
                <>
                  {index < 3 && (
                  <li>
                    <a href="#"  onClick={() => requestList(0, 'cat', item1.id)}>
                      {item1.name}
                    </a>
                  </li>
                  )}
                  </>
                ))}
                </ul>
                <Link
                  to="allcategories"
                  className="btn btn-outline-default"
                >
                  View All
                </Link>
              </div>
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryWithSubCategoryItems;
