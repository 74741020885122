import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../actions/actions';

const QueryForm = props => {
  const dispatch = useDispatch();
  const stateData = useSelector((state) => state);
  const [requirement, setRequirement] = useState("");
  const [name, setName] = useState("");
  const [unit, setUnit] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [cityList, setCityList] = useState("");
  const [stateList, setStateList] = useState("");
  const [countryList, setCountryList] = useState(stateData.country);

  const getState = (id) => {
    let index = countryList.findIndex(x => x.id == id);
    setStateList(countryList[index].state);
  }

  const getCity = (id) => {
    let index = stateList.findIndex(x => x.id == id);
    setCityList(stateList[index].city);
    alert(index);
  }
  var details = {};
  const pushQuery = () => {
    details['comp_id'] = props.user_id
    details['name'] = name;
    details['requirement'] = requirement;
    details['unit'] = unit;
    details['preference'] = 1;
    details['quantity'] = quantity;
    details['phone'] = phone;
    details['email'] = email;
    details['city'] = city;
    details['country_id'] = country;
    details['state_id'] = state;

    dispatch(actions.postLeads(details));
  }
  return (
    <div>
      <form className="mb-5" action="#">
        <h3 className="mb-3 font-weight-normal">Request Quotation</h3>

        <div className="form-group mb-6">
          <input type="text" className="form-control" placeholder='Name' onChange={e => setName(e.target.value)} />
        </div>
        <div className="form-group mb-6">
          <input type="text" className="form-control" placeholder='Phone' onChange={e => setPhone(e.target.value)} />
        </div>
        <div className="form-group mb-6">
          <input type="text" className="form-control" placeholder='Email' onChange={e => setEmail(e.target.value)} />
        </div>
        <div className="form-group mb-6">
          <select className="select-location form-control" onChange={e => {
            setCountry(e.target.value)
            getState(e.target.value)
          }}>
            <option value="">Select Country</option>
            {(countryList ? countryList : []).map((country) => {
              return <option value={country.id}>{country.name}</option>
            })
            }
          </select>
        </div>
        <div className="form-group mb-6">
          <select className="select-location form-control" onChange={e => {
            setState(e.target.value)
            getCity(e.target.value)
          }}>
            <option value="">Select State</option>
            {(stateList ? stateList : []).map((state) => {
              return <option value={state.id}>{state.name}</option>
            })
            }
          </select>
        </div>
        <div className="form-group mb-6">
          <select className="select-location form-control" onChange={e => setCity(e.target.value)}>
            <option value="">Select City</option>
            {(cityList ? cityList : []).map((city) => {
              return <option value={city.id}>{city.name}</option>
            })
            }
          </select>
        </div>
        <div className="form-group mb-6">
          <textarea type="text" className="form-control" placeholder='Query' onChange={e => setRequirement(e.target.value)}></textarea>
        </div>

        <div className="form-group mb-6">
          <div className='book-btn text-center' onClick={() => pushQuery()}>
            <a> Submit </a>
          </div>
          
        </div>

      </form>
    </div>
  );
};

QueryForm.propTypes = {

};

export default QueryForm;