import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../actions/actions';
import HorizontalMenu from '../../horizontalMenu';
import Heading from '../../heading';
import DataTable from 'react-data-table-component';

const CityList = props => {
    const stateData = useSelector((state) => state);
    // const columns = [
    //     {
    //         name: 'Title',
    //         selector: row => row.title,
    //         sortable: true,
    //     },
    //     {
    //         name: 'Year',
    //         selector: row => row.year,
    //     },
    // ];
    
    // const data = [
    //     {
    //         id: 1,
    //         title: 'Beetlejuice',
    //         year: '1988',
    //     },
    //     {
    //         id: 2,
    //         title: 'Ghostbusters',
    //         year: '1984',
    //     },
    // ]
    return (
        <>
            <HorizontalMenu list="/city/list" add="/city/add" />
            <section class="bg-light py-5 height100vh">
                <div class="container">
                    <Heading heading="Category List" />
                    <div class="card">
                        <div className="card-body px-7 pt-7 pb-0">
                           <table className='table table-hover'>
                               <tr>
                                   <th>S No</th>
                                   <th>Name</th>
                               </tr>
                               {(stateData.city ? stateData.city : []).map((city,index) => {
                               return ( <tr>
                                   <td>{index+1}</td>
                                   <td>{city.name}</td>
                               </tr>
                               )
                               })}
                           </table>
                           
                           {/* <DataTable
            columns={columns}
            data={data}
            pagination
            
        /> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};


export default CityList;