import React from 'react';

const PageTitle = (props) => {
  return (
    <div
        className="breadcrumb-section"
        style={{backgroundImage: "url(images/breadcrumb/breadcrumb-2.jpg)"}}
      >
        <div className="overlay op-5"></div>
        <div className="container">
          <div className="row align-items-center  pad-top-80">
            <div className="col-md-6 col-12">
              <div className="breadcrumb-menu">
                <h2 className="page-title">{props?.title}</h2>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="breadcrumb-menu text-right sm-left">
                <ul>
                  <li className="">
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="#">{props?.title}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default PageTitle;
