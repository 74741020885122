import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { actions } from "../../actions/actions";
import LeadList from "../leads/list";

function NewLeads(props) {
  return (
    <>
      <section class="pt-md-10 sec-pb-70 pb-6 bg-light">
        <div class="container">
          <div class="section-title ">
            <h2 className="blink">{props.title}</h2>
            <p>Explore and connect with great {props.type=='D'?'local':'International'} businesses </p>
          </div>
          <div
            class="row custom-scroll"
            style={{ height: 500, overflow: "auto" }}
          >
            <LeadList type={props.type} />
          </div>
        </div>
      </section>
    </>
  );
}

export default NewLeads;
