import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { actions } from '../../actions/actions';

const Login = props => {
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("")
    let details = {};
    const alert=()=>{
        
    }
    const onLoginPressed = () => {
        details["username"] = username;
        details["password"] = password;
        dispatch(actions.login(details))
        setTimeout(() => {
            navigate('/');
        }, 1500);
    }
    return (
        <div className="user-login-section section-padding bg-fixed" style={{backgroundImage: "url(images/header/hero-5.jpg)"}}>
    <div className="container">
        <div className="row">
            <div className="col-md-10 offset-md-1  text-center">
                <div className="login-wrapper">
                    <ul className="ui-list nav nav-tabs justify-content-center mar-bot-30" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" data-toggle="tab" href="#login" role="tab" aria-selected="true">Login</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#register" role="tab" aria-selected="false">Register</a>
                        </li>
                    </ul>
                    <div className="ui-dash tab-content">
                        <div className="tab-pane fade show active" id="login" role="tabpanel">
                            <form id="login-form" action="#" method="post">
                                <div className="form-group">
                                    <input type="text" name="username" value={username} onChange={e=>setUsername(e.target.value)} id="username" tabindex="1" className="form-control" placeholder="email" required />
                                </div>
                                <div className="form-group">
                                    <input type="password" onChange={e=>setPassword(e.target.value)} name="password" id="password" tabindex="2" value={password} className="form-control" placeholder="Password" />
                                </div>
                                <div className="row mar-top-20">
                                    {/* <div className="col-md-6 col-12 text-left">
                                        <div className="res-box">
                                            <input type="checkbox" tabindex="3" className="" name="remember" id="remember" />
                                            <label for="remember">Remember Me</label>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-6 col-12 text-right">
                                        <div className="res-box sm-left">
                                            <a href="#" tabindex="5" className="forgot-password">Forgot Password?</a>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="res-box text-center mar-top-30">
                                    <button type="button" className="btn v3" onClick={()=>onLoginPressed()}><i className="ion-log-in"></i> Log In</button>
                                </div>
                            </form>
                            <div className="social-profile-login text-center mar-top-30">
                                <h5>or Login with</h5>
                                <ul className="social-btn">
                                    <li className="bg-fb"><a href="#"><i className="ion-social-facebook"></i></a></li>
                                    <li className="bg-tt"><a href="#"><i className="ion-social-twitter"></i></a></li>
                                    <li className="bg-ig"><a href="#"><i className="ion-social-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="register" role="tabpanel">
                            <form id="register-form" action="#" method="post">
                                <div className="form-group">
                                    <input type="text" name="user_name" id="user_name" tabindex="1" className="form-control" placeholder="Username" value="" />
                                </div>
                                <div className="form-group">
                                    <input type="email" name="email" id="email" tabindex="1" className="form-control" placeholder="Email Address" value="" />
                                </div>
                                <div className="form-group">
                                    <input type="password" name="user_password" id="user_password" tabindex="2" className="form-control" placeholder="Password" />
                                </div>
                                <div className="form-group">
                                    <input type="password" name="confirm-password" id="confirm-password" tabindex="2" className="form-control" placeholder="Confirm Password" />
                                </div>
                                <div className="res-box text-left">
                                    <input type="checkbox" tabindex="3" className="" name="remember" id="remember" />
                                    <label for="remember">I've read and accept terms &amp; conditions</label>
                                </div>
                                <div className="res-box text-center mar-top-30">
                                    <button type="submit" className="btn v3"><i className="ion-android-checkmark-circle"></i>Sign Up</button>
                                </div>
                            </form>
                            <div className="social-profile-login text-center mar-top-30">
                                <h5>or Sign Up with</h5>
                                <ul className="social-btn">
                                    <li className="bg-fb"><a href="#"><i className="ion-social-facebook"></i></a></li>
                                    <li className="bg-tt"><a href="#"><i className="ion-social-twitter"></i></a></li>
                                    <li className="bg-ig"><a href="#"><i className="ion-social-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

    );
};

export default Login;