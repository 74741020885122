import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LeadDetailsModal from "../leadDetailsModal";
import Moment from "moment";

const LeadList = (props) => {
  const stateData = useSelector((state) => state);
  const [details, setDetails] = useState({});
  const [showModal, setshowModal] = useState(0);
  const navigate = useNavigate();
  console.log(props.type);
  var leadArray = [];
  if (props.type == "D") {
    stateData?.leads && stateData?.leads.forEach((el) => {
      if (el.country_id == 101) {
     
        leadArray.push(el);
      }
    });
  } else {
    stateData?.leads && stateData?.leads.forEach((el) => {
      if (el.country_id != 101) {
        leadArray.push(el);
      }
    });
  }
  const closeModal = () => {
    setshowModal(0);
  };
  return (
    <>
      <div className="col-lg-12">
        <div class="card">
          {/* <div class="card-header bg-white">
                        <span class="text-content">New Leads</span>
                    </div> */}

          <div class="card-body p-0 table-responsive">
            <table class="table table-hover mb-0">
              {/* <thead>
                                <th>Requirement</th>
                                <th>Requirement</th>
                                <th>Date</th>
                                <th></th>
                            </thead> */}
              <tbody>
                {(leadArray ? leadArray.slice(0, 10) : []).map(
                  (lead, index) => {
                    return (
                      <tr
                        className={
                          lead.quantity >= 100 ? "highlight text-white" : ""
                        }
                      >
                        <td class="text-capitalize">{lead.requirement}</td>
                        <td class="text-capitalize">
                          {" "}
                          {props.type == "D" && (
                            <img
                              src="/assets/img/svg/in.svg"
                              className="img50"
                            />
                          )}{" "}
                        </td>
                        <td class="text-capitalize">{lead.unit} </td>
                        <td>{Moment(lead.created_at).format("D MMM YYYY")}</td>
                        <td>{lead?.state?.name}</td>
                        <td>
                          <Link
                            to="#"
                            class="btn btn-sm btn-primary"
                            onClick={() => {
                              setDetails(lead);
                              setshowModal(1);
                            }}
                          >
                            Details
                          </Link>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
          <div class="card-footer bg-white">
            {/* <a href="my-bookings.html" class="btn btn-link p-0">all Leads</a> */}
          </div>
        </div>
        <div className="text-center">
          <Link to="/leads">
            <button className="btn btn-primary">View More</button>
          </Link>
        </div>
      </div>
      {showModal ? (
        <LeadDetailsModal details={details} hideModal={closeModal} />
      ) : (
        ""
      )}
    </>
  );
};

export default LeadList;
