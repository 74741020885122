import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { actions } from "./actions/actions";

const ScrollToTop = (props) => {
  const location = useLocation();
  const dispatch=useDispatch();
  useEffect(() => {
    if(location.pathname=="/"){
      localStorage.removeItem("searchby");
      dispatch(actions.getCompProfile());
    }
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>
};

export default ScrollToTop;