import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Heading from '../../../heading';

const PackageRequestList = props => {
    const stateData = useSelector((state) => state);
    return (
        <>
        <section class="bg-light py-5 height100vh">
                <div class="container">
                    <Heading heading="Packages Request List" />
                <div class="card">
                    {/* <div class="card-header bg-white">
                        <span class="text-content">New Leads</span>
                    </div> */}
                     <div className="card-body px-7 pt-7 pb-0">
                    <div class="card-body p-0 table-responsive">
                        <table class="table table-hover mb-0">
                            <thead>
                                <th>Name</th>
                                <th>Package</th>
                                <th>Phone</th>
                                <th>Alternate Number</th>
                                <th>Email</th>
                                <th>Date</th>
                            </thead>
                            <tbody>
                                {(stateData.packageRequest ? stateData.packageRequest.data : []).map((packageReq) => {
                                    return (<tr>
                                        <td>
                                            {packageReq.name}
                                        </td>
                                        <td>
                                            {packageReq.package}
                                        </td>
                                        <td class="text-capitalize">
                                            <a class="text-hover-primary text-muted" href="listing-rental.html">{packageReq.phone}</a>
                                        </td>
                                        <td class="text-capitalize"> {packageReq.altnumber} </td>
                                        <td class="text-capitalize"> {packageReq.email} </td>
                                        <td>
                                            {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(packageReq.created_at))}
                                        </td>
                                        {/* <td>
                                            <Link to="#" class="btn btn-sm btn-primary" onClick={() => {
                                                setDetails(lead)
                                                setshowModal(1)
                                            }
                                            }>Details</Link>
                                        </td> */}
                                    </tr>)
                                })}

                            </tbody>
                        </table>
                    </div>
                    </div>

                </div>
            </div>
            </section>
        </>
    );
};

export default PackageRequestList;