import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../../../heading';
import HorizontalMenu from '../../../../horizontalMenu';
import { appService } from '../../../../services/appServices';

const UserCategoryList = props => {
    const [categories,setCategories]=useState([]);
    appService.getUserCategory(appService.getUserDetails().id).then((data)=>{
        setCategories(data.data);
        // alert(JSON.stringify(data));
    })
    return (
        <>
            <HorizontalMenu list="/user/category/list" add="/user/category/add" />
            <section class="bg-light py-5 height100vh">
                <div class="container">
                    <Heading heading="Category List" />
                    <div class="card">
                        <div className="card-body px-7 pt-7 pb-0">
                           <table className='table table-hover'>
                               <tr>
                                   <th>S No</th>
                                   <th>Name</th>
                               </tr>
                               {categories.map((category,index) => {
                               return ( <tr>
                                   <td>{index+1}</td>
                                   <td>{category.name}</td>
                               </tr>
                               )
                               })}
                           </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};


export default UserCategoryList;