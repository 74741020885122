import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Review = props => {
    const [reviews,setReviews]=useState(props.reviews);
    return (
        <div className="my-6">
            <h3 className="font-weight-normal mb-6">Reviews ({props.reviews?.length})</h3>
            {props.reviews?.length==0&&
                <div className="media mb-3 text-center mt-3">
                    No Reviews Yet
                </div>
            }
            {props.reviews.map((review) => {
                return (
                    <div className="media mb-3">
                        <div className="media-img">
                            <img src="/assets/img/user/user-xs-1.jpg" className="mr-3 media-object rounded-circle" alt="Image User" />
                        </div>
                        <div className="media-body">
                            <h5 className="media-heading">Aaren</h5>

                            <p>{review.comment}</p>



                        </div>
                    </div>
                )})}
                < hr />
        </div>
    );
};

Review.propTypes = {

};

export default Review;