import * as actionTypes from "../actions/actionTypes"

 const setCategory = categories => ({
    type: actionTypes.SET_CATEGORY,
    categories
})
const setTestimonials = testimonials => ({
    type: actionTypes.SET_TESTIMONIALS,
    testimonials
})
const getTestimonials = (params) => ({
    type: actionTypes.GET_TESTIMONIALS,
    params
})
const getPackageRequest = (params) => ({
    type: actionTypes.GET_PACKAGE_REQUEST,
    params
})
 const setHomeListing = homeListing => ({
    type: actionTypes.SET_HOME_LISTING,
    homeListing
})
const getHomeListing = (params) => ({
    type: actionTypes.GET_HOME_LISTING,
    params
})
const setPackageRequest = packageRequest => ({
    type: actionTypes.SET_PACKAGE_REQUEST,
    packageRequest
})
const getCategory = (params) => ({
    type: actionTypes.GET_CATEGORY,
    params
})
const setMainCategory = mainCategories => ({
    type: actionTypes.SET_MAIN_CATEGORY,
    mainCategories
})
const getMainCategory = (params) => ({
    type: actionTypes.GET_MAIN_CATEGORY,
    params
})
const setSearch = search => ({
    type: actionTypes.SET_SEARCH,
    search
})
const getSearch = (params) => ({
    type: actionTypes.GET_SEARCH,
    params
})
const setCountry = country => ({
    type: actionTypes.SET_COUNTRY,
    country
})
const getCountry = (params) => ({
    type: actionTypes.GET_COUNTRY,
    params
})
const getArea = (params) => ({
    type: actionTypes.GET_AREA,
    params
})
const setArea = area => ({
    type: actionTypes.SET_AREA,
    area
})
const postCity = city => ({
    type: actionTypes.POST_CITY,
    city
})
const setCity = city => ({
    type: actionTypes.SET_CITY,
    city
})
const getCity = (params) => ({
    type: actionTypes.GET_CITY,
    params
})
const postState = state1 => ({
    type: actionTypes.POST_STATE,
    state1
})
const setState = state1 => ({
    type: actionTypes.SET_STATE,
    state1
})
const getState = (params) => ({
    type: actionTypes.GET_STATE,
    params
})
const postCategory = categories => ({
    type: actionTypes.POST_CATEGORY,
    categories
})
const postPackageRequest = packageRequest => ({
    type: actionTypes.POST_PACKAGE_REQUEST,
    packageRequest
})
const login=details=>({
    type: actionTypes.LOGIN,
    details
})
const register=details=>({
    type: actionTypes.REGISTER,
    details
})
const logout=details=>({
    type: actionTypes.LOGOUT
})
 const setUserDetails = userDetails => ({
    type: actionTypes.SET_USER,
    userDetails
})

const postProduct = product => ({
    type: actionTypes.POST_PRODUCT,
    product
})
const postUserCategory = category => ({
    type: actionTypes.POST_USER_CATEGORY,
    category
})
const setProduct = product => ({
    type: actionTypes.SET_PRODUCT,
    product
})
const getProduct = (params) => ({
    type: actionTypes.GET_PRODUCT,
    params
})

const postCompProfile = compProfile => ({
    type: actionTypes.POST_COMP_PROFILE,
    compProfile
})
const setCompProfile = compProfile => ({
    type: actionTypes.SET_COMP_PROFILE,
    compProfile
})
const getCompProfile = (params) => ({
    type: actionTypes.GET_COMP_PROFILE,
    params
})
const postLeads = leads => ({
    type: actionTypes.POST_LEADS,
    leads
})
const setLeads = leads => ({
    type: actionTypes.SET_LEADS,
    leads
})
const getLeads = (params) => ({
    type: actionTypes.GET_LEADS,
    params
})
const postReviews = review => ({
    type: actionTypes.POST_REVIEWS,
    review
})

const LoadingStatus=()=>({
    type: actionTypes.LOADING_STATUS,
})

const setLoadingStatus=(loadingStatus)=>({
    type:actionTypes.SET_LOADING_STATUS,
    loadingStatus
})

export const actions={
    setCategory,
    getCategory,
    setUserDetails,
    login,
    logout,
    postCategory,
    postCity,
    getCity,
    setCity,
    postState,
    getState,
    setState,
    getProduct,
    setProduct,
    postProduct,
    getArea,
    setArea,
    register,
    postCompProfile,
    setCompProfile,
    getCompProfile,
    getLeads,
    setLeads,
    postLeads,
    postReviews,
    postUserCategory,
    getCountry,
    setCountry,
    setSearch,
    getSearch,
    setMainCategory,
    getMainCategory,
    getPackageRequest,
    setPackageRequest,
    postPackageRequest,
    getHomeListing,
    setHomeListing,
    setTestimonials,
    getTestimonials,
    setLoadingStatus,
    LoadingStatus,
    // doCommentLike,
    // postFeedComments
}