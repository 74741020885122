import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { actions } from '../../actions/actions';
import HorizontalMenu from '../../horizontalMenu';
import Heading from '../../heading';

const State = props => {
    const dispatch = useDispatch();
    const [state, setState] = useState("");
    const [countryId, setCountryId] = useState(1);
    let details = {};

    const pushState = () => {
        details["name"] = state;
        details["country_id"] = countryId;
        dispatch(actions.postState(details))
    }
    return (
        <>
            <HorizontalMenu list="/state/list" add="/state/add" />
            <section class="bg-light py-5 height100vh">
                <div class="container">
                    <Heading heading="Add State" />
                    <div class="card">
                        <div className="card-body px-7 pt-7 pb-0">
                            <div className="form-group mb-7">
                                <label htmlFor="exampleInputText">State*</label>
                                <input type="text" className="form-control"
                                    onChange={(e) => setState(e.target.value)}
                                    aria-describedby="emailHelp" />
                                <em>Enter state.</em>
                            </div>
                            <div className="form-group d-flex justify-content-between align-items-center mb-7">
                                <button type="button" className="btn btn-outline-primary text-uppercase" onClick={() => pushState()}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};


export default State;