import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actions } from "../../actions/actions";

const Slider = (props) => {
  const stateData = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [type, setType] = useState(1);
  const [term, setTerm] = useState("");
  var details = {};
  const search = async () => {
      details['type'] = type;
      details['term'] = term.trim(" ");
      details['page'] = 0;
      localStorage.setItem("searchby","search");
  localStorage.setItem("searchid",term.trim(" "));
      await dispatch(actions.getSearch(details));
      setTerm("");
      setTimeout(() => {
          if (type == 2) {
              navigate("/search/lead");
          }
          else {
              navigate("/listing");
          }
      }, 1000);

  }
  const haldleInput = (e) => {
      setTerm(e.target.value)
  }
  return (
    <div className="hero v1 section-padding bg-zoom">
      <div className="overlay op-3"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h1 className="hero__title places-tab">What's Your Plan Today ?</h1>
            <h1 className="hero__title events-tab">Explore great events.</h1>
            <p className="hero__description">
              All the top locations – from restaurants and clubs, to galleries,
              famous places and more..
            </p>
          </div>
          <div className="col-md-12 text-center mar-top-20">
            <ul className="hero__list">
              <li className="hero__list-item">
                <a
                  className={`place ${type == 1 ? "active-list" : ""}`}
                  href="#"
                  onClick={(e) => setType(1)}
                >
                  Products <i className="icofont-google-map" aria-hidden="true"></i>
                </a>
              </li>
              <li className="hero__list-item">
                <a className={`event ${type == 2 ? "active-list" : ""}`} href="#" onClick={(e) => setType(2)}>
                  Leads<i className="icofont-list"></i>
                </a>
              </li>
            </ul>
            <form className="hero__form v1 bg-white">
              <div className="row">
                {/* <div className="col-lg-3 col-md-12">
                  <select className="hero__form-input custom-select">
                    <option>Select Location </option>
                    <option>Product</option>
                    <option>Lead</option>
                  </select>
                </div> */}
                <div className="col-lg-10 col-md-12">
                  <input
                    className="hero__form-input w-100"
                    type="text"
                    name="place-event"
                    id="place-event"
                    value={term}
                    onChange={(e) => haldleInput(e)}
                    placeholder="What are you looking for?"
                  />
                </div>

                {/* <div className="col-lg-3 col-md-12">
                  <select className="hero__form-input custom-select">
                    <option>Select Categories</option>
                    <option>Art's</option>
                    <option>Health</option>
                    <option>Hotels</option>
                    <option>Real Estate</option>
                    <option>Rentals</option>
                  </select>
                </div> */}
                <div className="col-lg-2 col-md-12">
                  <div className="submit_btn text-right md-left">
                    <button className="btn v3 text-right" type="button"  onClick={() => search()}>
                      <i className="ion-search" aria-hidden="true"></i> Search
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
