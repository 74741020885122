import React, { useState } from "react";
import { appService } from "../../../../services/appServices";

const CompanyAbout = () => {
  const [owner_name, setOwnerName] = useState("");
  const [ownership_type, setOwnershipType] = useState("");
  const [year_established, setYearEstablished] = useState("");
  const [business_nature, setBusinessNature] = useState("");
  const [company_size, setCompanySize] = useState("");
  const [annual_turnover, setAnnualTurnover] = useState("");
  const [company_video, setCompanyVideo] = useState("");
  const [about_description, setAboutUs] = useState("");
  const [user_id, setUserId] = useState(JSON.parse(localStorage.getItem('user')).id);

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "owner_name":
        setOwnerName(value);
        break;
      case "ownership_type":
        setOwnershipType(value);
        break;
      case "year_established":
        setYearEstablished(value);
        break;
      case "business_nature":
        setBusinessNature(value);
        break;
      case "company_size":
        setCompanySize(value);
        break;
      case "annual_turnover":
        setAnnualTurnover(value);
        break;
      case "company_video":
        setCompanyVideo(value);
        break;
      case "about_description":
        setAboutUs(value);
        break;
      default:
        break;
    }
  };

  // Function to gather form data into an object
  const getFormData = () => {
    const formData = {
      user_id,
      owner_name,
      ownership_type,
      year_established,
      business_nature,
      company_size,
      annual_turnover,
      company_video,
      about_description,
    };
    return formData;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = getFormData();
    appService.addCompanyDetails(formData).then((data)=>{
        console.log("added")
    }).catch(err=>{
        console.log(err)
    })
    console.log(formData); // You can send this data to your backend or handle it as needed
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>
              Owner / CEO / Director Name <small className="danger">*</small>
            </label>
            <input
              type="text"
              name="owner_name"
              value={owner_name}
              onChange={handleInputChange}
              className="form-control"
              placeholder="Enter Company Owner Name"
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              Ownership Type<small className="danger">*</small>
            </label>
            <select
              name="ownership_type"
              value={ownership_type}
              onChange={handleInputChange}
              className="form-control"
            >
              <option value="">Select Ownership Type</option>
              <option value="1">Pvt Ltd Company</option>
              <option value="2">One Person Company</option>
              <option value="3">Limited company</option>
              <option value="4">Non-Profit Organisation</option>
              <option value="5">LLP Company</option>
              <option value="6">Partnership Company</option>
              <option value="7">Public Limited Company</option>
              <option value="8">Sole Proprietorship</option>
              <option value="9">Others</option>
            </select>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>Year of Establishment</label>
            <input
              type="number"
              name="year_established"
              value={year_established}
              onChange={handleInputChange}
              className="form-control"
              placeholder="Ex. 2019"
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              Primary Business<small className="danger">*</small>
            </label>
            <select
              name="business_nature"
              value={business_nature}
              onChange={handleInputChange}
              className="form-control"
            >
              <option value="">-Select Business Type-</option>
              <option value="1">Importer</option>
              <option value="2">Manufacturer</option>
              <option value="3">Seller/Buyer</option>
              <option value="4">Exporter</option>
              {/* Other options */}
            </select>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>No. of Employees</label>
            <select
              name="company_size"
              value={company_size}
              onChange={handleInputChange}
              className="form-control"
            >
              <option value="">-Select No. of Employees-</option>
              <option value="1">Upto 05 People</option>
              <option value="2">06 to 10 People</option>
              {/* Other options */}
            </select>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>Annual Turnover</label>
            <select
              name="annual_turnover"
              value={annual_turnover}
              onChange={handleInputChange}
              className="form-control"
            >
              <option value="">-Select Turnover-</option>
              <option value="1">Rs. Less than 1 Crore</option>
              {/* Other options */}
            </select>
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label>Company Video</label>
            <input
              type="text"
              name="company_video"
              value={company_video}
              onChange={handleInputChange}
              className="form-control"
              placeholder="Enter video URL"
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label>About Company</label>
            <textarea
              name="about_description"
              value={about_description}
              onChange={handleInputChange}
              className="form-control"
              rows="8"
              placeholder="Write about your Company"
            />
          </div>
        </div>

        <div className="col-md-12">
          <button type="submit" className="btn btn-danger">
            <i className="la la-check-square-o"></i> Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default CompanyAbout;
