import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../actions/actions';
import Menu from './menu';
import Heading from '../../heading';
import HorizontalMenu from '../../horizontalMenu';

const Product = props => {
    const stateData = useSelector((state) => state);
    // alert(JSON.stringify(stateData.area));
    const dispatchProd = useDispatch();
    const [category, setCategory] = useState("");
    const [subCategory, setSubCategory] = useState("");
    const [title, setTitle] = useState("");
    const [compName, setCompName] = useState("");
    const [minOrderQty, setMinOrderQty] = useState("");
    // const [price,setPrice]=useState("");
    const [unit, setUnit] = useState("");
    const [price, setPrice] = useState("");
    const [unitPrice, setUnitType] = useState("");
    const [content, setContent] = useState("");
    const [rank, setRank] = useState("");
    const [city, setCity] = useState("");
    const [area, setArea] = useState("");
    const [img, setImg] = useState("");
    const [prodCode, setProdCode] = useState("");
    const [dispatch, setDispatch] = useState("");
    const [prodCapacity, setProdCapacity] = useState("");
    const [deliveryTime, setDeliveryTime] = useState("");
    const [videoURL, setVideoUrl] = useState("");
    const [outofStock, setOutOfStock] = useState("");
    const [recomended, setRecommended] = useState("");
    var details = {};
    const pushProduct = () => {
        details['user_id'] = 2;
        details['group_id'] = 0;
        details['category_id'] = category;
        details['subcategory_id'] = 0;
        details['child_id'] = 0;
        details['title'] = title;
        details['min_order_qty'] = minOrderQty;
        details['price'] = price;
        details['unit'] = unit;
        details['unit_type_id'] = 1;
        details['content'] = content;
        details['company_name'] = compName;
        // details['folder_path'] =folder_path;
        details['star_rank'] = rank;
        details['city_id'] = city;
        details['area_id'] = area;
        details['img'] = img;
        details['product_code'] = prodCode;
        details['port_of_dispatch'] = dispatch;
        details['production_capacity'] = prodCapacity;
        details['delivery_time'] = deliveryTime;
        details['video_url'] = videoURL;
        details['brochure'] = '';
        details['hot_flag'] = 0;
        details['out_of_stock_flag'] = outofStock;
        details['recommended_flag'] = recomended;
        details['group_category'] = 0;
        details['unique_id'] = 0;
        details['new_url'] = 0;
        details['shop_flag'] = 0;
        details['shop_deals_flag'] = 0;
        details['shop_featured_flag'] = 0;
        details['shop_new_flag'] = 0;
        details['status'] = 1;
        dispatchProd(actions.postProduct(details))
    }
    return (
        <>
            <HorizontalMenu add="/product/add" />
            <section class="bg-light py-5 height100vh">
                <div class="container">
                    <Heading heading="Add Product"/>
                    <form>
                        <div class="card">
                            <div class="card-body px-6 pt-6 pb-1">
                                <form action="" method="post">
                                    <div class="row">


                                        <div class="form-group col-md-6 mb-6">
                                            <label for="">Category</label>
                                            <div class="select-default bg-white">
                                                <select class="select-location form-control" onChange={e => setCategory(e.target.value)}>
                                                    <option>Select Category</option>
                                                    {(stateData.categories ? stateData.categories : []).map((category) => {
                                                        return <option value={category.id}>{category.name}</option>
                                                    })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6 mb-6">
                                            <label for="">Sub Category</label>
                                            <div class="select-default bg-white">
                                                <select class="select-location form-control" onChange={e => setSubCategory(e.target.value)}>
                                                    <option>Select Sub Category</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6 mb-6">
                                            <label for="listingTitle">Title</label>
                                            <input type="text" class="form-control" placeholder="Title" name="" onChange={e => setTitle(e.target.value)} />
                                        </div>
                                        <div class="form-group col-md-6 mb-6">
                                            <label for="listingTitle">Company Name</label>
                                            <input type="text" class="form-control" placeholder="Company Name" name="" onChange={e => setCompName(e.target.value)} />
                                        </div>
                                        <div class="form-group col-md-6 mb-6">
                                            <label for="listingTitle">Min Order Qty</label>
                                            <input type="text" class="form-control" placeholder="Min Order Qty" name="" onChange={e => setMinOrderQty(e.target.value)} />
                                        </div>
                                        <div class="form-group col-md-6 mb-6">
                                            <label for="listingTitle">Price</label>
                                            <input type="text" class="form-control" placeholder="Price" name="" onChange={e => setPrice(e.target.value)} />
                                        </div>
                                        <div class="form-group col-md-6 mb-6">
                                            <label for="listingTitle">Unit</label>
                                            <input type="text" class="form-control" placeholder="Unit" name="" onChange={e => setUnit(e.target.value)} />
                                        </div>
                                        <div class="form-group col-md-6 mb-6">
                                            <label for="">Unit Type</label>
                                            <div class="select-default bg-white">
                                                <select class="select-location form-control" onChange={e => setUnitType(e.target.value)}>
                                                    <option>Select Unit Type</option>
                                                    <option value="Kg">Kg</option>
                                                    <option value="Pcs">Pcs</option>
                                                    <option value="Pkt">Pkt</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-12 mb-6">
                                            <label for="discribeTheListing">Content</label>
                                            <textarea class="form-control" rows="6" placeholder="Content" onChange={e => setContent(e.target.value)}></textarea>
                                        </div>
                                        <div class="form-group col-md-6 mb-6">
                                            <label for="">Star Rank</label>
                                            <div class="select-default bg-white">
                                                <select class="select-location form-control" onChange={e => setRank(e.target.value)}>
                                                    <option>Select Rank</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6 mb-6">
                                            <label for="">City</label>
                                            <div class="select-default bg-white">
                                                <select class="select-location form-control" onChange={e => setCity(e.target.value)}>
                                                    <option>Select City</option>
                                                    {(stateData.city ? stateData.city : []).map((city) => {
                                                        return <option value={city.id}>{city.name}</option>
                                                    })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6 mb-6">
                                            <label for="">Area</label>
                                            <div class="select-default bg-white">
                                                <select class="select-location form-control" onChange={e => setArea(e.target.value)}>
                                                    <option>Select Area</option>
                                                    {(stateData.area ? stateData.area : []).map((areas) => {
                                                        return <option value={areas.id}>{areas.name}</option>
                                                    })
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-6 mb-6">
                                            <label for="listingTitle">Image</label>
                                            <input type="file" class="form-control" name="" onChange={e => setImg(e.target.value)} />
                                        </div>

                                        <div class="form-group col-md-6 mb-6">
                                            <label for="addTags">Product Code</label>
                                            <input type="text" class="form-control" placeholder="Product Code" onChange={e => setProdCode(e.target.value)} />
                                        </div>
                                        <div class="form-group col-md-6 mb-6">
                                            <label for="addTags">Port of Dispatch</label>
                                            <input type="text" class="form-control" placeholder="Port of Dispatch" onChange={e => setDispatch(e.target.value)} />
                                        </div>
                                        <div class="form-group col-md-6 mb-6">
                                            <label for="addTags">Production Capacity</label>
                                            <input type="text" class="form-control" placeholder="Production Capacity" onChange={e => setProdCapacity(e.target.value)} />
                                        </div>
                                        <div class="form-group col-md-6 mb-6">
                                            <label for="addTags">Delivery Time</label>
                                            <input type="text" class="form-control" placeholder="Delivery Time" onChange={e => setDeliveryTime(e.target.value)} />
                                        </div>
                                        <div class="form-group col-md-12 mb-6">
                                            <label for="addTags">Video URL</label>
                                            <input type="text" class="form-control" placeholder="Video URL" onChange={e => setVideoUrl(e.target.value)} />
                                        </div>
                                        <div class="form-group col-md-5 mb-6" style={{ marginLeft: 23 }}>
                                            <input type="checkbox" class="form-check-input" id="Out of Stock" checked onChange={e => setOutOfStock(e.target.value)} />
                                            <label class="form-check-label" for="monday">Out of Stock</label>
                                        </div>
                                        <div class="form-group col-md-5 mb-6" style={{ marginLeft: 23 }}>
                                            <input type="checkbox" class="form-check-input" id="Recommended" checked onChange={e => setRecommended(e.target.value)} />
                                            <label class="form-check-label" for="monday">Recommended Product</label>
                                        </div>

                                    </div>
                                </form>

                            </div>
                        </div>


                        <div class="row justify-content-center">
                            <div class="col-md-7 col-lg-6 col-xl-5">
                                <div class="mb-6">
                                    <button type="button" class="btn btn-lg btn-primary btn-block" onClick={() => pushProduct()}>submit</button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </section>
        </>
    );
};


export default Product;