import React from "react";
import PropTypes from "prop-types";

const Payments = (props) => {
  return (
    <div>
      <section class="py-7 py-md-10 bg-light height100vh">
        <div class="container">
          <div class="border p-6 p-lg-9 bg-white">
            <div class="row">
              <div class="col-md-6 col-xs-12 border-md-right pr-md-6 pr-lg-9">
                <h2 class="h3 mb-6">Bank Details</h2>
                <table className="table">
                  <tr>
                    <td>Bank Name</td>
                    <td>IDFC First Bank</td>
                  </tr>
                  <tr>
                    <td>Account Name:</td>
                    <td>Digital Growth Marketing Technology.</td>
                  </tr>
                  <tr>
                    <td>Account Number:</td>
                    <td>10121420446</td>
                  </tr>
                  <tr>
                    <td>IFSC Code:</td>
                    <td>IDFB0020143</td>
                  </tr>
                  <tr>
                    <td>Branch Name:</td>
                    <td>Prashant Vihar, New Delhi</td>
                  </tr>
                </table>
              </div>

              <div class="col-md-6 col-xs-12 pl-md-6 pl-lg-9">
                <h2 class="h3 mb-6">Scan & Pay</h2>
                <div>
                  <img
                    src="../assets/qrcode.jpeg"
                    className="img-responsive"
                    style={{ width: "80%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Payments;
