import React from "react";
import { Outlet, Route } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";

export const PublicRoutes = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};
