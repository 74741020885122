import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Heading from '../../heading';
import LeadDetailsModal from '../leadDetailsModal';


const UserLeadList = props => {
    const stateData = useSelector((state) => state);
    var user = JSON.parse(localStorage.getItem('user'));
    const [details, setDetails] = useState({});
    const [showModal, setshowModal] = useState(0);
    const navigate = useNavigate();
    var leadArray = (stateData.leads ? stateData.leads : []).filter(function (el) {
        return el.comp_id == props.user_id;
    });
    const closeModal = () => {
        setshowModal(0);
    }
    return (
        <>
            <section class="bg-light py-5 height100vh">
                <div class="container">
                    <Heading heading="Leads" />
                    <div class="card">
                        <div className='col-lg-12'>
                            <div class="carjjd">
                                {/* <div class="card-header bg-white">
                                    <span class="text-content">New Leads</span>
                                </div> */}

                                <div class="card-body p-0 table-responsive">
                                    <table class="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Requirement</th>
                                                <th>Posted Date</th>
                                                <th>View</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(leadArray ? leadArray : []).map((lead) => {
                                                return (<tr>
                                                    <td>
                                                        {lead.name}
                                                    </td>
                                                    <td class="text-capitalize">
                                                        <a class="text-hover-primary text-muted" href="listing-rental.html">{lead.requirement}</a>
                                                    </td>
                                                    <td>
                                                        {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(lead.created_at))}
                                                    </td>
                                                    <td>
                                                        <Link to="#" onClick={() => {
                                                            setDetails(lead)
                                                            setshowModal(1)
                                                        }
                                                        }>Details</Link>
                                                    </td>
                                                </tr>)
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-footer bg-white">
                                    {/* <a href="my-bookings.html" class="btn btn-link p-0">all Leads</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {showModal ?
                <LeadDetailsModal details={details} hideModal={closeModal} />
                : ""}
        </>
    );
};

export default UserLeadList;