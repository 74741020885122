function Service() {
  return (
    <section className="hero-catagory mar-top-30 section-padding">
      <div className="container">
        {/* <div className="border rounded pt-7"> */}
          <div className="row">
          <div class="col-md-8 offset-md-2 text-center"><h2 class="section-title v1">Services</h2></div>
            <div className="col-sm-4 col-xs-12">
              <div className="card border-0 text-center">
                <div className="icon-lg m-auto rounded-circle">
                  <i className="icon-listy icon-key text-primary"></i>
                </div>
                <div className="card-body">
                  <h2 className="h4 font-weight-normal mb-3">
                    Secure Transactions{" "}
                  </h2>
                  <p>
                    We give priority to successful and secure transactions to
                    grow your business. We protect your sensitive data and
                    provide a secure environment for communication and financial
                    transactions. Our secure payment gateway ensures that your
                    sensitive information remains confidential throughout the
                    payment process. Our Victory Lies in Your Success.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-4 col-xs-12">
              <div className="card border-0 text-center">
                <div className="icon-lg m-auto rounded-circle">
                  <i className="icon-listy icon-wreath text-primary"></i>
                </div>
                <div className="card-body">
                  <h2 className="h4 font-weight-normal mb-3">
                    Dedicated customer support
                  </h2>
                  <p>
                    We have an excellent customer service and support team to
                    help and assist you grow your business from scratch to
                    success. Our team is available 24*7 at your service to
                    address any of your queries, concerns, or technical issues.
                    Our custom house agents facilitate successful global trade
                    between buyers and suppliers.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-4 col-xs-12">
              <div className="card border-0 text-center">
                <div className="icon-lg m-auto rounded-circle">
                  <i className="icon-listy icon-tag3 text-primary"></i>
                </div>
                <div className="card-body">
                  <h2 className="h4 font-weight-normal mb-3">
                    Seamless Trade Experience
                  </h2>
                  <p>
                    Our user-friendly interface makes it easy for you to use the
                    search functionality to find products and services to
                    fulfill your requirements. Browse through thousands of
                    listings, compare prices, read detailed product
                    descriptions, and connect directly with suppliers. Our
                    amazing platform mmakes the process of sourcing products,
                    negotiating deals, and making purchases effortless and easy
                    to use.
                  </p>
                </div>
              </div>
            </div>
          </div>
        {/* </div> */}
      </div>
    </section>
  );
}

export default Service;
