import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { actions } from "../../actions/actions";
import Carousel from "react-bootstrap/Carousel";
import * as $ from "jquery";
import { appService } from "../../services/appServices";
import { Constants } from "../../contants";

function BrandBanner() {
  const [brands, setBrand] = useState([]);
  function chunkArray(array, chunkSize) {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    // console.log(chunks);
    return chunks;
  }
  useEffect(()=>{
    appService.getBrandBaner().then((brand)=>{
        // console.log(brand);
        // setBrand(brand.data);
        setBrand(chunkArray(brand.data,4));
    });
  },[])
//   console.log(brands);
  return (
    <section class="pt-7 pt-md-10">
      <div class="container">
        <div class="section-title">
          {/* <h2>Trending Categories</h2>
          <p></p> */}
        </div>
        <div class="row justify-content-center marquee">
          <Carousel style={{width:'100%'}}>
            {brands?.map((brand, index) => {
              return (
                <Carousel.Item interval={4000} indicators={false}>
                  <div class="row">
                    {brand?.map((brand1, index) => {
                      return (
                        <div class="col-md-12 col-lg-3" key={index}>
                              <div class="card">
                                  <a href={brand1.link} target="_blank">
                                      <img
                                          class="card-img-top"
                                          style={{ width: '100%' }}
                                          src={Constants.STORAGE_LINK + brand1.img}
                                          alt="Card image cap" />
                                  </a>
                              </div>
                          </div>
                      );
                    })}
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
        {/* <div class="text-center pt-5">
                    <a href="Trending-grid-right-sidebar.html" class="btn btn-primary">Explore More</a>
                </div> */}
      </div>
    </section>
  );
}

export default BrandBanner;
