import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LeadDetailsModal from '../leadDetailsModal';
import Heading from '../../heading';

const LeadSearch = props => {
    const stateData = useSelector((state) => state);
    const [details, setDetails] = useState({});
    const [showModal, setshowModal] = useState(0);
    const [leadData, setLeadData] = useState(stateData.search ? stateData.search : []);
    const closeModal = () => {
        setshowModal(0);
    }
    useEffect(() => {
        setLeadData(stateData.search);
    }, [JSON.stringify(stateData.search)])

    return (
        <section class="bg-light py-5 height100vh">
            <div class="container">
                <Heading heading="Leads" />
                <h6>Search Result : {leadData.total}</h6>
                <div class="card">
                    <div id="table-list-owner_wrapper" class="dataTables_wrapper no-footer">
                        <table id="table-list-owner" class="table table-hover" style={{ width: '100%' }} role="grid">
                            <thead>
                                <tr>
                                <th>Name</th>
                                <th>Requirement</th>
                                <th>Quantity</th>
                                <th>Value</th>
                                <th>Date</th>
                                <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {(leadData?leadData.data:[]).map((lead) => {
                                    return (<tr>
                                        <td>
                                            {lead.name}
                                        </td>
                                        <td class="text-capitalize">
                                            <a class="text-hover-primary text-muted" href="listing-rental.html">{lead.requirement}</a>
                                        </td>
                                        <td class="text-capitalize"> {lead.quantity>0?lead.quantity:"NA"} {lead.unit!=0?lead.unit:""} </td>
                                        <td>
                                            {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(lead.created_at))}
                                        </td>
                                        <td>
                                            <Link to="#" onClick={() => {
                                                setDetails(lead)
                                                setshowModal(1)
                                            }
                                            }>Details</Link>
                                        </td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                        {showModal ?
                            <LeadDetailsModal details={details} hideModal={closeModal} />
                            : ""}
                    </div>
                </div>
            </div>
        </section>
    )
};

export default LeadSearch;