import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import LeadDetailsModal from '../leadDetailsModal';
import Moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { actions } from '../../actions/actions';

const AllLeads = props => {
    const stateData = useSelector((state) => state);
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const [detailss, setDetails] = useState({});
    const [showModal, setshowModal] = useState(0);
    var leadArray = (stateData.leads ? stateData.leads : []).filter(function (el) {
        return el.comp_id == null;
    });
    const closeModal = () => {
        setshowModal(0);
    }
    return (
        <div>
            <section class="bg-light py-8">
                <div class="container">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                        <nav aria-label="breadcrumb">
                            <h2 class="breadcrumb-title text-center mb-4">Buy Leads</h2>
                            {/* <ul class="breadcrumb bg-transparent justify-content-center p-0 m-0">
          <li class="breadcrumb-item"><a href="index-2.html">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Pricing Plan</li>
        </ul> */}
                        </nav>
                    </div>
                </div>
            </section>
            <div class="element-wrapper">
                <section class="py-7 py-md-10">
                    <div class="container">
                        <div>
                            <div class="card">
                                {/* <div class="card-header bg-white">
                        <span class="text-content">New Leads</span>
                    </div> */}

                                <div class="card-body p-0 table-responsive">
                                    <table class="table table-hover mb-0">
                                        <thead>
                                            <th>Name</th>
                                            <th>Requirement</th>
                                            <th>Quantity</th>
                                            <th>Value</th>
                                            <th>Date</th>
                                            <th></th>
                                        </thead>
                                        <tbody>
                                            {(leadArray ? leadArray : []).map((lead, index) => {
                                                return (

                                                    <tr>
                                                        <td>
                                                            {lead.name}
                                                        </td>
                                                        <td class="text-capitalize">
                                                            {lead.requirement}
                                                        </td>
                                                        <td class="text-capitalize"> {lead.quantity} </td>
                                                        <td class="text-capitalize">{lead.unit} </td>
                                                        <td>
                                                            {Moment(lead.created_at).format('D MMM YYYY')}
                                                        </td>
                                                        <td>
                                                            <Link to="#" class="btn btn-sm btn-primary" onClick={() => {
                                                                setDetails(lead)
                                                                setshowModal(1)
                                                            }
                                                            }>Details</Link>
                                                        </td>
                                                    </tr>)
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {showModal ?
                            <LeadDetailsModal details={detailss} hideModal={closeModal} />
                            : ""}
                    </div>
                </section>
            </div>
        </div>
    );
};

export default AllLeads;