import { SelectionHandleType } from 'ag-grid-community';
import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { actions } from '../../actions/actions';

function LocationSearch(props) {
    const stateData = useSelector((state) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [type, setType] = useState(1);
    const [term, setTerm] = useState("");
    var details = {};
    const search = async () => {
        details['type'] = type;
        details['term'] = term.trim(" ");
        details['page'] = 0;
        localStorage.setItem("searchby","search");
    localStorage.setItem("searchid",term.trim(" "));
        await dispatch(actions.getSearch(details));
        setTerm("");
        setTimeout(() => {
            if (type == 2) {
                navigate("/search/lead");
            }
            else {
                navigate("/listing");
            }
        }, 1000);

    }
    const haldleInput = (e) => {
        setTerm(e.target.value)
    }
    return (
        <><div className='col-auto py-5 px-8 d-none d-sm-block'>
            <div className="search-wraper">
                <div className="input-group">
                    <div className="input-group-prepend">
                        <select onChange={(e) => setType(e.target.value)} className="form-control">
                            <option value="1">Product/Service</option>
                            <option value="2">Leads</option>
                        </select>
                    </div>
                    <input type="text" value={term} onChange={(e) => haldleInput(e)} className="form-control" placeholder="Enter your keywords" />

                    <button onClick={() => search()} type="button" className="btn btn-sm btn-primary rounded-0">
                        Search
                    </button>
                </div>
            </div>
        </div>
        </>

    )
}

export default LocationSearch;