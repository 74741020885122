import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../actions/actions";
import HorizontalMenu from "../../horizontalMenu";
import Heading from "../../heading";
import { appService } from "../../services/appServices";

const SubCategoryList = (props) => {
  const [subcategory, setSubCategory] = useState([]);
  useEffect(() => {
    appService.getSubCategory().then((data) => {
      setSubCategory(data.data);
    });
  }, []);
  return (
    <>
      <HorizontalMenu list="/subcategory/list" add="/subcategory/add" />
      <section class="bg-light py-5 height100vh">
        <div class="container">
          <Heading heading="Category List" />
          <div class="card">
            <div className="card-body px-7 pt-7 pb-0">
              <table className="table table-hover">
                <tr>
                  <th>S No</th>
                  <th>Name</th>
                </tr>
                {subcategory.map((category, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{category.name}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubCategoryList;
