import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../../../../actions/actions';
import Heading from '../../../../heading';
import HorizontalMenu from '../../../../horizontalMenu';
import { appService } from '../../../../services/appServices';

const UserCategory = props => {
    const dispatch = useDispatch();
    const [category, setCategory] = useState("");
    let details = {};

    const pushCategory = () => {
        details["name"] = category;
        details["user_id"] = appService.getUserDetails().id;
        dispatch(actions.postUserCategory(details))
    }
    return (
        <>
            <HorizontalMenu list="/user/category/list" add="/user/category/add" />
            <section class="bg-light py-5 height100vh">
                <div class="container">
                    <Heading heading="Add Category" />
                    <div class="card">
                        <div className="card-body px-7 pt-7 pb-0">
                            <div className="form-group mb-7">
                                <label htmlFor="exampleInputText">Category*</label>
                                <input type="text" className="form-control"
                                    onChange={(e) => setCategory(e.target.value)}
                                    aria-describedby="emailHelp" />
                                <em>Enter category.</em>
                            </div>
                            <div className="form-group d-flex justify-content-between align-items-center mb-7">
                                <button type="button" className="btn btn-outline-primary text-uppercase" onClick={() => pushCategory()}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default UserCategory;