import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const PannelHeader = () => {
    const [showSidebar,setShowSidebar]=useState(false);
    const location=useLocation();
    const [role, setRole] = useState("");
    useEffect(() => {
    //   setUser(JSON.parse(localStorage.getItem("user")));
      let user = JSON.parse(localStorage.getItem("user"));
      let userRole = user ? user.role : "";
      setRole(userRole);
    }, []);
    useEffect(()=>{
        setShowSidebar(false)
    },[location])
  return (
    <header class="db-top-header">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-9 col-sm-6 col-4">
            <div class="site-navbar-wrap v2">
              <div class="site-navbar">
                <div class="row align-items-center">
                  <nav class="site-navigation float-left">
                    <div class="container">
                      <ul class="site-menu js-clone-nav d-none d-lg-block">
                        <li>
                          <Link to="/">Home</Link>
                          {/* <ul class="dropdown">
                            <li>
                              <a href="home-v1.html">Home Tab</a>
                            </li>
                            <li>
                              <a href="home-v2.html">Home Hero</a>
                            </li>
                            <li>
                              <a href="home-v3.html">Home Video</a>
                            </li>
                            <li>
                              <a href="home-v4.html">Home parallax</a>
                            </li>
                            <li>
                              <a href="home-v5.html">Home Classic</a>
                            </li>
                            <li>
                              <a href="home-v6.html">Home Map</a>
                            </li>
                            <li>
                              <a href="home-v7.html">Home Slider</a>
                            </li>
                            <li>
                              <a href="home-v8.html">Home Video Fullscreen</a>
                            </li>
                            <li>
                              <a href="home-v9.html">Home Hero Fullscreen</a>
                            </li>
                            <li>
                              <a href="home-v10.html">Home Map Fullscreen</a>
                            </li>
                          </ul> */}
                        </li>
                        {/* <li class="has-children">
                          <a href="#">Listings</a>
                          <ul class="dropdown">
                            <li class="has-children">
                              <a href="#">List Layout</a>
                              <ul class="dropdown sub-menu">
                                <li>
                                  <a href="list-fullwidth.html">Full Width</a>
                                </li>
                                <li>
                                  <a href="list-fullwidth-map.html">
                                    Fullwidth with map
                                  </a>
                                </li>
                                <li>
                                  <a href="list-left-sidebar.html">
                                    Left Sidebar
                                  </a>
                                </li>
                                <li>
                                  <a href="list-right-sidebar.html">
                                    Right Sidebar
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li class="has-children">
                              <a href="#">Grid Layout</a>
                              <ul class="dropdown sub-menu">
                                <li>
                                  <a href="grid-fullwidth.html">Full Width</a>
                                </li>
                                <li>
                                  <a href="grid-fullwidth-map.html">
                                    Fullwidth with map
                                  </a>
                                </li>
                                <li>
                                  <a href="grid-two-column.html">
                                    Grid two column
                                  </a>
                                </li>
                                <li>
                                  <a href="grid-left-sidebar.html">
                                    Left Sidebar
                                  </a>
                                </li>
                                <li>
                                  <a href="grid-right-sidebar.html">
                                    Right Sidebar
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li class="has-children">
                              <a href="#">Map sidebar Layout</a>
                              <ul class="dropdown sub-menu">
                                <li>
                                  <a href="list-left-sidemap.html">
                                    List Left sidemap
                                  </a>
                                </li>
                                <li>
                                  <a href="list-right-sidemap.html">
                                    List Right sidemap
                                  </a>
                                </li>
                                <li>
                                  <a href="grid-left-sidemap.html">
                                    grid Left sidemap
                                  </a>
                                </li>
                                <li>
                                  <a href="grid-right-sidemap.html">
                                    grid Right sidemap
                                  </a>
                                </li>
                                <li>
                                  <a href="grid-search-half-map.html">
                                    advanced search
                                  </a>
                                </li>
                                <li>
                                  <a href="grid-search-half-map.html">
                                    advanced search
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li class="has-children">
                              <a href="#">Category Listings</a>
                              <ul class="dropdown sub-menu">
                                <li>
                                  <a href="all-categories.html">All Category</a>
                                </li>
                                <li>
                                  <a href="all-locations.html">All Locations</a>
                                </li>
                              </ul>
                            </li>
                            <li class="has-children">
                              <a href="#">Listing Details</a>
                              <ul class="dropdown sub-menu">
                                <li>
                                  <a href="single-listing-one.html">
                                    Single Listing one
                                  </a>
                                </li>
                                <li>
                                  <a href="single-listing-two.html">
                                    Single Listing two
                                  </a>
                                </li>
                                <li>
                                  <a href="single-listing-three.html">
                                    Single Listing three
                                  </a>
                                </li>
                                <li>
                                  <a href="single-listing-four.html">
                                    Single Listing four
                                  </a>
                                </li>
                                <li>
                                  <a href="single-listing-five.html">
                                    Single Listing five
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li class="has-children">
                          <a href="#">Pages</a>
                          <ul class="dropdown">
                            <li>
                              <a href="about.html">About us</a>
                            </li>
                            <li>
                              <a href="contact.html">Contact us</a>
                            </li>
                            <li class="has-children">
                              <a href="#">News Layout</a>
                              <ul class="dropdown sub-menu">
                                <li>
                                  <a href="news-left-sidebar.html">
                                    News Left sidebar
                                  </a>
                                </li>
                                <li>
                                  <a href="news-right-sidebar.html">
                                    News right sidebar
                                  </a>
                                </li>
                                <li>
                                  <a href="news-without-sidebar.html">
                                    News without sidebar
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li class="has-children">
                              <a href="#">Single News Layout</a>
                              <ul class="dropdown sub-menu">
                                <li>
                                  <a href="single-news-one.html">
                                    Single News one
                                  </a>
                                </li>
                                <li>
                                  <a href="single-news-two.html">
                                    Single News two
                                  </a>
                                </li>
                                <li>
                                  <a href="single-news-three.html">
                                    Single News three
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="404-page.html">404 error</a>
                            </li>
                            <li>
                              <a href="add-listing.html">Add Listing</a>
                            </li>
                            <li>
                              <a href="booking.html">Booking Page</a>
                            </li>
                            <li>
                              <a href="booking-confirmation.html">
                                Booking Confirmation
                              </a>
                            </li>
                            <li>
                              <a href="login.html">Login</a>
                            </li>
                            <li>
                              <a href="pricing-table.html">Pricing Table</a>
                            </li>
                            <li>
                              <a href="user-profile.html">User profile</a>
                            </li>
                            <li>
                              <a href="faq.html">FAQ</a>
                            </li>
                            <li>
                              <a href="invoice.html">invoice</a>
                            </li>
                          </ul>
                        </li>
                        <li class="has-children">
                          <a href="#">User Panel</a>
                          <ul class="dropdown">
                            <li>
                              <a href="db.html">Dashboard</a>
                            </li>
                            <li>
                              <a href="db-my-listing.html">My Listings</a>
                            </li>
                            <li>
                              <a href="db-bookings.html">Bookings</a>
                            </li>
                            <li>
                              <a href="db-messages.html">Inbox</a>
                            </li>
                            <li>
                              <a href="db-favourites.html">Favourite Listing</a>
                            </li>
                            <li>
                              <a href="db-add-listing.html">Add Listing</a>
                            </li>
                            <li>
                              <a href="db-recieve-reviews.html">Reviews</a>
                            </li>
                            <li>
                              <a href="db-my-profile.html">User Profile</a>
                            </li>
                            <li>
                              <a href="db-invoices.html">Invoices </a>
                            </li>
                          </ul>
                        </li>
                        <li class="d-lg-none">
                          <a class="btn v1" href="add-listing.html">
                            Add Listing <i class="ion-plus-round"></i>
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </nav>
                  <div class="d-lg-none sm-right">
                    <a href="#" class="mobile-bar js-menu-toggle">
                      <span class="ion-android-menu"></span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="site-mobile-menu">
                <div class="site-mobile-menu-header">
                  <div class="site-mobile-menu-close  js-menu-toggle">
                    <span class="ion-ios-close-empty"></span>
                  </div>
                </div>
                <div class="site-mobile-menu-body"></div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-8">
            <div class={`header-button ${window.innerWidth>786 && "d-none"}`}>
              
              <div class="header-button-item js-sidebar-btn" onClick={()=>setShowSidebar(!showSidebar)}>
                <img src="images/clients/reviewer-4.png" alt="..." />
                <span>
                  Steve <i class="ion-arrow-down-b"></i>
                </span>
              </div>
              <div class={`setting-menu js-right-sidebar ${showSidebar?"right0":""}`}>
                <div class="account-dropdown__body">
                  <div class="account-dropdown__item">
                    <Link to="admin/company/list">Company Listing</Link>
                  </div>
                  <div class="account-dropdown__item">
                    <Link to="category/list">Category</Link>
                  </div>
                  <div class="account-dropdown__item">
                    <Link to="subcategory/list">Sub Category</Link>
                  </div>
                  <div class="account-dropdown__item">
                    <Link to="microcategory/list">Micro Category</Link>
                  </div>
                  <div class="account-dropdown__item">
                    <Link to="state/list">State</Link>
                  </div>
                  <div class="account-dropdown__item">
                    <Link to="city/list">City</Link>
                  </div>
                  <div class="account-dropdown__item">
                    <Link to="admin/packagereq/list">Package Requests</Link>
                  </div>
                  <div class="account-dropdown__item">
                    <a href="#">
                      <i class="ion-ios-upload-outline"></i>Logout
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default PannelHeader;
