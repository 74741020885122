import Map from "./map";
import LocationSearch from "./locationSearch";
import Listing from "./listing";
import Service from "./service";
import Download from "./download";
import Slider from "./slider";
import NewLeads from "./leadSection";
import Testimonials from "./testimonials";
import Trending from "./trending";
import HomeCity from "./city";
import HowItWorks from "./howItWorks";
import HomeCountry from "./country";
import LocationSearchMobile from "./locationSearchMobile";
import TextTestimonials from "./textTestimonials";
import BrandBanner from "./brandBanner";
import { useDispatch, useSelector } from "react-redux";
import { appService } from "../../services/appServices";
import { actions } from "../../actions/actions";
import SuppliersCity from "../common/home/supplier-city";
import SuppliersCountry from "../common/home/supplier-india";
import CategoryWithSubCategoryItems from "../common/home/category-with-sub-items";
import Category from "../common/home/category";
import CategoryWithImage from "../common/home/category-with-image";

function Home() {
  const mainCat = useSelector((state) => state.mainCategories);
  return (
    <>
      <Slider />
      <Category data={mainCat} />
      <div className="row">
        {mainCat?.map(
          (item, index) =>
            index > 5 && index <= 9 && <CategoryWithImage data={item} />
        )}
      </div>
      <div className="mb-4">
        <CategoryWithSubCategoryItems data={mainCat?.slice(9, 15)} />
      </div>
      <div className="mb-4">
        <CategoryWithSubCategoryItems data={mainCat?.slice(15, 21)} />
      </div>
      <div className="mb-4">
        <CategoryWithSubCategoryItems data={mainCat?.slice(21, 26)} />
      </div>
      <SuppliersCity />
       {/* <Listing /> */}
       <div className="row bg-light">
        <div className="col-md-6">
          <NewLeads title="LATEST BUY LEADS (Domestic)" type="D" />
        </div>
        <div className="col-md-6">
          <NewLeads title="LATEST BUY LEADS (International)" type="I" />
        </div>
      </div>
      <SuppliersCountry />
      <Service />
      {/* <section>
        <div class="card-body p-8 ">
          <h2 class="h4 font-weight-normal mb-3">
            connect2buyers: Experience India’s largest B2B platform
          </h2>
          <p>
            Discover a world of possibilities in the realm of
            business-to-business trade with connect2buyers, India's emerging and
            leading B2B platform. We have created a seamless and efficient
            online platform for you. Here you can connect, collaborate and take
            your business to new heights of success by connecting with verified
            and trustworthy buyers and sellers from various industries. Many
            businesses have made themselves successful by joining our
            organization, now it's your turn.{" "}
          </p>
        </div>
      </section>
      <LocationSearchMobile />
      <Trending />
      <BrandBanner />
      <Category showCatCount={12} />
      <div className="row bg-light">
        <div className="col-md-6">
          <NewLeads title="LATEST BUY LEADS (Domestic)" type="D" />
        </div>
        <div className="col-md-6">
          <NewLeads title="LATEST BUY LEADS (International)" type="I" />
        </div>
      </div>

      <HomeCity />
      <HomeCountry />
      <Service />
      <Testimonials />
      <div className="row bg-light">
        <div className="col-md-6">
          <TextTestimonials />
        </div>
        <div className="col-md-6">
          <HowItWorks />
        </div>
      </div> */}
    </>
  );
}

export default Home;
