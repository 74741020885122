import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { actions } from "../../actions/actions";
import * as $ from 'jquery';
import { Constants } from "../../contants";



function Category(props) {
    const stateData = useSelector((state) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const catCountShow = props.showCatCount;

    $("div.service-card")
        .mouseout(function () {
            $(this).addClass("service-height");
        })
        .mouseover(function () {
            $(this).removeClass("service-height");
        });

    // useEffect(() => {
    //     setMainCategories(stateData.mainCategories);
    // }, [JSON.stringify(mainCategories)])
    const requestList = (selectedPage, name, id) => {
        localStorage.setItem("searchby",name);
    localStorage.setItem("searchid",id);
        dispatch(actions.getCompProfile({ name: name, id: id, page: selectedPage }))
        navigate('/listing');
    }
    return (
        <>
            <section className={props.showCatCount > 12 ? "pt-md-10 sec-pb-70 pb-6" : "pt-md-10 sec-pb-70 pb-6 bg-light"}>
                <div className="container">
                    <div className="section-title">
                        <h2>Browse by Categories</h2>
                        <p>Explore and connect with great local businesses </p>
                    </div>
                    <div className="row">
                        {(stateData.mainCategories ? stateData.mainCategories.slice(0, catCountShow) : []).map((mainCategory) => {
                            return (<div className="col-md-6 col-lg-3 category">
                                <div className="card bg-transparent service-height service-card">
                                <img class="card-img-top" src={Constants.COMP_IMG_URL+"category/"+mainCategory.img} alt="Card image cap" style={{width:260,height:180}} />
                                    <div className="card-body">
                                        {/* <div className="icon-md">
                                        <i className="icon-listy icon-car-1"></i>
                                    </div> */}
                                        <h3 className="h5 catheading capitalize mb-3">{mainCategory.name}</h3>
                                        <hr />
                                        <ul className="list-unstyled list-gray mb-0">
                                            {(mainCategory.category ? mainCategory.category : []).map((category) => {
                                                return <li className="pb-1" onClick={() => requestList(0, 'cat', category.id)}><Link to="#">{category.name}</Link></li>
                                            })
                                            }


                                        </ul>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                    </div>
                    {props.showCatCount > 12 ? "" : <div class="text-center pt-5">
                        <Link to="/allcategories" class="btn btn-primary">Explore More</Link>
                    </div>
                 }
                </div>
            </section>
        </>

    )
}

export default Category;
