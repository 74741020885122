import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const sucessAlert=(message)=>{
   toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
}

const errorAlert=(message)=>{
    toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
}

const infoAlert=(message)=>{
    toast.info(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
}

export const AlertService = {
    sucessAlert,
    errorAlert,
    infoAlert
};