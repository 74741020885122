import { useRef } from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

function Chat() {
  const tawkMessengerRef = useRef();

  const handleMinimize = () => {
    tawkMessengerRef.current.minimize();
  };

  return (
    <TawkMessengerReact
      propertyId="631cc2cd54f06e12d893e746"
      widgetId="1gck5htat"
      ref={tawkMessengerRef}
    />
  );
}

export default Chat;
