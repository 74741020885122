import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../actions/actions";
import HorizontalMenu from "../../horizontalMenu";
import Heading from "../../heading";
import { appService } from "../../services/appServices";

const MicroCategory = (props) => {
  const stateData = useSelector((state) => state);
  const dispatch = useDispatch();
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [categories, setcategories] = useState([]);
  useEffect(() => {
    appService.getSubCategory().then((data) => {
      setcategories(data.data);
    });
  }, []);
  let details = {};

  const pushCategory = () => {
    details["sub_cat_id"] = category;
    details["name"] = subCategory;
    appService.addMicroCategory(details).then((data) => {
      console.log(data);
    });
  };
  return (
    <>
      <HorizontalMenu list="/microcategory/list" add="/microcategory/add" />
      <section class="bg-light py-5 height100vh">
        <div class="container">
          <Heading heading="Add Micro Category" />
          <div class="card">
            <div className="card-body px-7 pt-7 pb-0">
              <div className="form-group mb-7">
                <label htmlFor="exampleInputText">Sub Category*</label>
                <select
                  className="form-control"
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option>Select Sub Category</option>
                  {categories.map((category) => {
                    return <option value={category.id}>{category.name}</option>;
                  })}
                </select>
                <em>Enter sub category.</em>
              </div>
              <div className="form-group mb-7">
                <label htmlFor="exampleInputText">Sub Category*</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setSubCategory(e.target.value)}
                  aria-describedby="emailHelp"
                />
                <em>Enter category.</em>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center mb-7">
                <button
                  type="button"
                  className="btn btn-outline-primary text-uppercase"
                  onClick={() => pushCategory()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MicroCategory;
